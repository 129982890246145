import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Dashboards from './components/Dashboards';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import Settings from './components/Settings';
import Home from './components/Home';
import About from './components/About';
import MarketStatus from './components/MarketStatus';
import Cookies from 'js-cookie';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import { Suspense, lazy } from 'react';
import './css/FramerButton.css';
import SidebarMenu from './components/SidebarMenu';
import Playground from './components/Playground';
import { MenuProvider } from './components/MenuContext';
import APIChatComponent from './components/APIChatComponent';
import "./css/custom-scrollbar.css";
import '@radix-ui/themes/styles.css';
import { useLocation } from 'react-router-dom';
import usePageTracking from './Tracking';
import Text2Image from "@/components/Text2Image";
import FeatureGrid from './components/FeatureGrid';
import BackgroundRemover from './components/BackgroundRemover';
import AuraSrUpscaler from './components/AuraSrUpscaler';
import CreativeUpscaler from './components/CreativeUpscaler';
import FaceRetoucher from './components/FaceRetoucher';
import FaceToSticker from './components/FaceToSticker';
import Blog from './components/Blog';
import PostDetail from './components/PostDetail';
import Text2Video from './components/Text2Video';

const ChatComponent = lazy(() => {
if (process.env.REACT_APP_CHAT_TYPE === '1') {
console.log("init sse");
return import('./components/ChatComponentSse');
} else if(process.env.REACT_APP_CHAT_SSE === '0') {
console.log("init fetch");
return import('./components/ChatComponent');
} else {
console.log("init fetch");
return import('./components/ChatComponentWs');
}
});

const clientId = '792826448769-dehk5ulv2rljsl3e3bhfvmrjslcr0jem.apps.googleusercontent.com';

const App = () => {
return (
<GoogleOAuthProvider clientId={clientId}>
<MenuProvider>
<Router>
<AppContent />
</Router>
</MenuProvider>
</GoogleOAuthProvider>
);
};

const AppContent = () => {
usePageTracking();
const [isAuthenticated, setIsAuthenticated] = useState(false);
const [userInfo, setUserInfo] = useState(null);
const [loading, setLoading] = useState(true);
const [isSideMenuOpen, setIsSideMenuOpen] = useState(window.innerWidth >= 768); // 根据初始屏幕宽度设置菜单状态

const location = useLocation();
const isToggleButtonWhite = location.pathname === '/' || location.pathname === '/settings';
const menuBarRef = useRef(null);

useEffect(() => {
const storedUserInfo = Cookies.get('userInfo');
if (storedUserInfo) {
setIsAuthenticated(true);
setUserInfo(JSON.parse(storedUserInfo));
}
setLoading(false);

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
response => response,
error => {
if (error.response && error.response.status === 401) {
handleLogout();
return <Navigate to="/login" />;
}
return Promise.reject(error);
}
);

window.axiosInstance = axiosInstance;

const handleResize = () => {
if (window.innerWidth < 768) {
//setIsSideMenuOpen(false);
} else {
setIsSideMenuOpen(true);
}
};

// 初始检测窗口宽度
handleResize();

// 添加窗口 resize 事件监听器
window.addEventListener('resize', handleResize);

// 仅在移动设备上添加点击外部关闭菜单的事件监听器
if (window.innerWidth < 768) {
// const handleClickOutside = (event) => {
// if (menuBarRef.current && !menuBarRef.current.contains(event.target)) {
// setIsSideMenuOpen(false);
// }
// };
// document.addEventListener('mousedown', handleClickOutside);

return () => {
// document.removeEventListener('mousedown', handleClickOutside);
window.removeEventListener('resize', handleResize);
};
}

return () => {
window.removeEventListener('resize', handleResize);
};
}, []);

const handleLogout = () => {
Cookies.remove('userInfo');
Cookies.remove('refreshToken');
Cookies.remove('expiryDate');
Cookies.remove('idToken');
localStorage.removeItem('accessToken');
setIsAuthenticated(false);
setUserInfo(null);
};

const toggleMenu = () => {
setIsSideMenuOpen(!isSideMenuOpen);
};

const formatUsername = (username) => {
if (username) {
return username.length > 12 ? `${username.substring(0, 12)}...` : username;
} else {
return 'Anonymous';
}
};

if (loading) {
return <div>...</div>;
}

return (
<div className="flex flex-row justify-start h-full w-full">
<SidebarMenu ref={menuBarRef}
isSideMenuOpen={isSideMenuOpen}
setIsSideMenuOpen={setIsSideMenuOpen}
isAuthenticated={isAuthenticated}
setIsAuthenticated={setIsAuthenticated}
userInfo={userInfo}
handleLogout={handleLogout}
/>
<div className="flex flex-col flex-1 overflow-y-auto bg-gray-100">
<button className={`md:hidden absolute top-2 right-2 m-2.5 z-50 glowingButton ${isToggleButtonWhite ? 'text-blue-500' : 'text-blue-500'}`} onClick={toggleMenu}>
<FaBars className="h-4 w-4" />
</button>
<div className="h-full flex-grow overflow-y-auto">
<Routes>
<Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} setUserInfo={setUserInfo} />} />
<Route path="/dashboards" element={<SecureRoute isAuthenticated={isAuthenticated}><Dashboards /></SecureRoute>} />
<Route path="/dashboard/:dashboardId" element={<Dashboard />} />
<Route path="/chat-stock" element={<Suspense fallback={<div></div>}>
<ChatComponent />
</Suspense>} />
<Route path="/playground" element={<Playground />} />
<Route path="/chat-session/:id" element={<APIChatComponentWrapper />} />
<Route path="/settings" element={<SecureRoute isAuthenticated={isAuthenticated}><Settings userInfo={userInfo} setUserInfo={setUserInfo} /></SecureRoute>} />
<Route path="/about" element={<About />} />
<Route path="/text2image" element={<Text2Image />} />
<Route path="/market" element={<MarketStatus />} />
<Route path="/" element={<Home />} />
<Route path="/aitools" element={<FeatureGrid />} />
<Route path="/rembg" element={<BackgroundRemover />} />
<Route path="/aurasr" element={<AuraSrUpscaler />} />
<Route path="/creativeupscaler" element={<CreativeUpscaler />} />
<Route path="/faceretoucher" element={<FaceRetoucher />} />
<Route path="/face2sticker" element={<FaceToSticker />} />
<Route path="/blog" element={<Blog />} />
<Route path="/post/:id" element={<PostDetail />} />
<Route path="/text2video" element={<Text2Video />} />

</Routes>
</div>
</div>
</div>
);
};

const APIChatComponentWrapper = () => {
const { id } = useParams();
return <APIChatComponent sessionId={id} />;
};

const SecureRoute = ({ children, isAuthenticated }) => {
if (!isAuthenticated) {
console.log('Not authenticated');
return <Navigate to="/login" />;
}
return children;
};

export default App;
