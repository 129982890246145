import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { LsIcons } from './ui/LsIcons';
import "../css/radixdialog.css";

const VideoCard = ({ video, onDelete }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div>
        <div className="bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:translate-y-[-10px] hover:shadow-lg">
            {video.loading ? (
                <div className="flex items-center justify-center h-full">
                    <div className="loader"></div>
                </div>
            ) : video.error ? (
                <div className="card-body">
                    <p className="text-red-500">Error: {video.error}</p>
                </div>
            ) : (
                <video src={video.video_url} controls className="w-full h-auto rounded-lg" />
            )}
            <div className="card-body">
                <div className="flex justify-between items-center">
                    <button className="flex m-2 btn btn-secondary" onClick={() => setShowInfo(true)}>
                        {LsIcons.Text2Image_info_icon}
                    </button>
                    <button className="flex m-2 btn btn-danger" onClick={() => onDelete(video.id)}>
                        {LsIcons.Text2Image_delete_icon}
                    </button>
                </div>
            </div>

            
        </div>
        {showInfo && (
                <Dialog.Root open={showInfo} onOpenChange={setShowInfo}>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content className="DialogContent">
                        <Dialog.Title className="DialogTitle">Video Info</Dialog.Title>
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center justify-between">
                                <label className="font-bold">Prompt</label>
                                <button className="Button small" onClick={() => navigator.clipboard.writeText(video.prompt || '')}>
                                    {LsIcons.Text2Image_copy_svg}
                                </button>
                            </div>
                            <textarea
                                className="border p-2 rounded w-full"
                                rows={Math.min(Math.ceil((video.prompt || '').length / 45), 6)}
                                readOnly
                                value={video.prompt || ''}
                            />
                            <div>
                                <label className="font-bold">Negative Prompt</label>
                                <textarea
                                    className="border p-2 rounded w-full"
                                    rows={Math.min(Math.ceil((video.negative_prompt || '').length / 45), 6)}
                                    readOnly
                                    value={video.negative_prompt || ''}
                                />
                            </div>
                            <div>
                                <label className="font-bold">Other Parameters</label>
                                <textarea
                                    className="border p-2 rounded w-full"
                                    rows={3}
                                    readOnly
                                    value={JSON.stringify({
                                        seed: video.seed,
                                        steps: video.steps,
                                        guidance_scale: video.guidance_scale,
                                        ...video.otherParams
                                    }, null, 2) || ''}
                                />
                            </div>
                        </div>
                        <Dialog.Close asChild>
                            <button className="Button green mt-4">
                                Close
                            </button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Root>
            )}
        </div>
    );
};

export default VideoCard;
