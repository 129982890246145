import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FaSave } from 'react-icons/fa';
import { LsIcons } from './ui/LsIcons';
import './ui/css/Settings.css';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const Settings = ({ userInfo, setUserInfo }) => {
    const [username, setUsername] = useState(userInfo.lusername) || null;
    const [openaiUrl, setOpenaiUrl] = useState('');
    const [openaiKey, setOpenaiKey] = useState('');
    const [groqUrl, setGroqUrl] = useState('');
    const [groqKey, setGroqKey] = useState('');
    const [ollamaUrl, setOllamaUrl] = useState('');
    const [message, setMessage] = useState('');
    const [qwenKey, setQwenKey] = useState('');
    const [deepseekKey, setDeepseekKey] = useState('');
    const [deepseekUrl, setDeepseekUrl] = useState('https://api.deepseek.com/v1'); // 新增 DeepSeek URL 状态
    const location = useLocation();
    const apiLinks = [
        {
            name: "OpenAI",
            url: "https://platform.openai.com/api-keys",
            img: "/icons/openai.svg"
        },
        {
            name: "Groq",
            url: "https://console.groq.com/keys",
            img: "/icons/groq.png"
        },
        {
            name: "Qwen",
            url: "https://bailian.console.aliyun.com/?spm=5176.28197581.0.0.58e529a42ojiWO&apiKey=1#/api-key",
            img: "/icons/qwen.png"
        },
        {
            name: "DeepSeek",
            url: "https://platform.deepseek.com/api_keys",
            img: "/icons/deepseek.png"
        }
    ];

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Chat stock Component" });
    }, []);

    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                const response = await axios.get(`/api/getusersetting?luserid=${userInfo.luserid}`);
                const settings = response.data;
                setOpenaiUrl(settings.openaiurl);
                setOpenaiKey(settings.openaikey);
                setGroqUrl(settings.groqurl);
                setGroqKey(settings.groqkey);
                setOllamaUrl(settings.ollamaurl);
                setQwenKey(settings.qwenkey);
                setDeepseekKey(settings.deepseekkey);
                setDeepseekUrl(settings.deepseekurl || 'https://api.deepseek.com/v1'); // 设置 DeepSeek URL
            } catch (error) {
                console.error('Failed to fetch user settings:', error);
            }
        };

        fetchUserSettings();
    }, [userInfo.luserid]);

    const handleUsernameChange = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/changeusername', {
                luserid: userInfo.luserid,
                lusername: username
            });

            if (response.data.success) {
                const updatedUserInfo = { ...userInfo, lusername: username };
                setUserInfo(updatedUserInfo);
                Cookies.set('userInfo', JSON.stringify(updatedUserInfo));
                setMessage('Change username successfully.');
            } else {
                setMessage('Username already exists.');
            }
        } catch (error) {
            console.error('Failed to change username:', error);
        }
    };

    const handleSaveSettings = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/updateusersetting', {
                luserid: userInfo.luserid,
                openaiurl: openaiUrl,
                openaikey: openaiKey,
                groqurl: groqUrl,
                groqkey: groqKey,
                ollamaurl: ollamaUrl,
                qwenkey: qwenKey,
                deepseekkey: deepseekKey,
                deepseekurl: deepseekUrl // 添加 DeepSeek URL
            });
            setMessage('Save successfully.');
        } catch (error) {
            console.error('Failed to save settings:', error);
        }
    };

    return (
        <div className="flex items-center flex-col justify-center min-h-screen bg-gray-100 dark:bg-gray-800">
        <div className="settings-container">
            <form>
                <div className="settings-card">
                    <h2>Account Settings</h2>
                    <label>Username:</label>
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
                        />
                        <button onClick={handleUsernameChange}>
                            {LsIcons.Settings_change_name_svg_icon}
                        </button>
                    </div>
                </div>

                <div className="settings-card">
                    <h2>API Keys on Cloud:</h2>
                    <div className="flex flex-col space-y-4 rel relative">
                        <div>
                            <label>OpenAI Key:</label>
                            <input
                                type="text"
                                placeholder="Enter OpenAI Key"
                                value={openaiKey}
                                onChange={(e) => setOpenaiKey(e.target.value)}
                                className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
                            />
                        </div>
                        <div>
                            <label>Groq Key:</label>
                            <input
                                type="text"
                                placeholder="Enter Groq Key"
                                value={groqKey}
                                onChange={(e) => setGroqKey(e.target.value)}
                                className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
                            />
                        </div>
                        <div>
                            <label>Qwen Key:</label>
                            <input
                                type="text"
                                placeholder="Enter Qwen Key"
                                value={qwenKey}
                                onChange={(e) => setQwenKey(e.target.value)}
                                className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
                            />
                        </div>
                        <div>
                            <label>DeepSeek Key:</label>
                            <input
                                type="text"
                                placeholder="Enter DeepSeek Key"
                                value={deepseekKey}
                                onChange={(e) => setDeepseekKey(e.target.value)}
                                className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
                            />
                        </div>
                        {/* 隐藏的 DeepSeek URL 输入框 */}
                        <input
                            type="hidden"
                            value={deepseekUrl}
                            onChange={(e) => setDeepseekUrl(e.target.value)}
                        />
                        <div className="flex justify-end">
                            <button title="save" onClick={handleSaveSettings} className="text-center bg-gray-500 text-white border rounded-md py-2 px-4 hover:bg-gray-600 transition-colors duration-300 text-sm">
                                <FaSave className="icon" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-8 p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 text-sm">
                    <h3 className="text-lg font-semibold mb-4">Get API Keys:</h3>
                    <div className="flex space-x-4">
                        {apiLinks.map((link, index) => (
                            <button
                                key={index}
                                className="bg-transparent rounded-lg p-2 cursor-pointer outline-none focus:outline-none hover:shadow-md transition-shadow duration-300"
                                onClick={() => window.open(link.url, "_blank")}
                            >
                                <img
                                    src={link.img}
                                    alt={`${link.name} logo`}
                                    className="h-12 w-12 transform transition duration-300 hover:scale-110"
                                />
                            </button>
                        ))}
                    </div>
                </div>

                {message && <p className="message">{message}</p>}
            </form>
        </div>
        </div>
    );
};

export default Settings;
