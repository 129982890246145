import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import FeatureCard from './FeatureCard';
import { BackgroundGradientAnimation } from './ui/background-gradient-animation';
import WorldClock from './WordClock';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const location = useLocation();
  const containerRef = useRef(null);
  const worldClockRef = useRef(null);
  const [worldClockHeight, setWorldClockHeight] = useState(0);
  const [features, setFeatures] = useState([]);

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (worldClockRef.current) {
        const height = worldClockRef.current.offsetHeight;
        setWorldClockHeight(height);
      }
    };
    const timer = setTimeout(updateHeight, 200);
    updateHeight();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Home' });

    const fetchFeatures = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_API_BASE_URL}/landingfeatures`);
        const data = response.data.map(feature => ({
          ...feature,
          imageSrc: `${process.env.REACT_APP_CMS_API_BASE_URL}${feature.imageSrc.url}`
        }));
        setFeatures(shuffleArray(data));
      } catch (error) {
        console.error('Error fetching features:', error);
      }
    };

    fetchFeatures();
  }, [location.pathname]);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <BackgroundGradientAnimation>
      <div ref={containerRef} className="flex flex-grow overflow-hidden bg-gray-100 relative">
        <Container>
          <canvas id="matrix"></canvas>
        </Container>
        <div
          ref={worldClockRef}
          className="flex flex-grow w-11/12 mt-12 flex-wrap justify-center items-center absolute top-1/12 left-1/2 -translate-x-1/2"
        >
          <WorldClock />
        </div>
        <div className="carousel-container absolute justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
          {features.length > 0 ? (
            <Slider {...settings}>
              {features.map((feature) => (
                <FeatureCard
                  key={feature.id}
                  title={feature.title}
                  description={feature.description}
                  imageSrc={feature.imageSrc}
                  linkTo={feature.linkTo}
                />
              ))}
            </Slider>
          ) : (
            <p>Loading features...</p>
          )}
        </div>
      </div>
    </BackgroundGradientAnimation>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export default Home;
