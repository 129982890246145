import React, { useState, useRef, useEffect } from 'react';
import * as fal from "@fal-ai/serverless-client";
import FalApiKeyDialog from './FalApiKeyDialog';
import FalImageUploader from './FalImageUploader';
import { LsIcons } from './ui/LsIcons';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import * as Slider from '@radix-ui/react-slider';

const FaceToSticker = () => {
  const [originalImage, setOriginalImage] = useState(null);
  const [processedImages, setProcessedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
  const [prompt, setPrompt] = useState('a person');
  const [negativePrompt, setNegativePrompt] = useState('moustache, blurry, low resolution, low res, low quality');
  const [numInferenceSteps, setNumInferenceSteps] = useState(20);
  const [guidanceScale, setGuidanceScale] = useState(4.5);
  const [instantIdStrength, setInstantIdStrength] = useState(0.7);
  const [ipAdapterWeight, setIpAdapterWeight] = useState(0.2);
  const [ipAdapterNoise, setIpAdapterNoise] = useState(0.5);
  const [upscale, setUpscale] = useState(true);
  const [upscaleSteps, setUpscaleSteps] = useState(10);
  const [seed, setSeed] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState({});
  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "facesticker" });
    const savedApiKey = localStorage.getItem('falApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, []);

  const handleImageUpload = async (file) => {
    if (originalImage) {
      URL.revokeObjectURL(originalImage);
    }
    setOriginalImage(URL.createObjectURL(file));
    setProcessedImages([]);
    setSelectedImage(null);
    uploadedFileRef.current = file;
    setIsProcessing(false);
    setIsLoading(false);
    setLoadingImages({});
  };

  const processImage = async () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Face to Sticker'
    });
    if (!originalImage || !apiKey || !uploadedFileRef.current) {
      setIsApiKeyDialogOpen(true);
      return;
    }

    setIsProcessing(true);
    setIsLoading(true);

    try {
      fal.config({ credentials: apiKey });

      const uploadedUrl = await fal.storage.upload(uploadedFileRef.current);

      const result = await fal.subscribe("fal-ai/face-to-sticker", {
        input: {
          image_url: uploadedUrl,
          prompt: prompt,
          negative_prompt: negativePrompt,
          num_inference_steps: numInferenceSteps,
          guidance_scale: guidanceScale,
          instant_id_strength: instantIdStrength,
          ip_adapter_weight: ipAdapterWeight,
          ip_adapter_noise: ipAdapterNoise,
          upscale: upscale,
          upscale_steps: upscaleSteps,
          seed: seed || undefined
        },
        logs: true,
        onQueueUpdate: (update) => {
          if (update.status === "IN_PROGRESS") {
            update.logs.map((log) => log.message).forEach(console.log);
          }
        },
      });

      setProcessedImages(result.images);
      setSelectedImage(result.images[0].url);
      setLoadingImages(result.images.reduce((acc, img) => ({ ...acc, [img.url]: true }), {}));
      console.log("Processed images:", result.images);
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setIsProcessing(false);
      setIsLoading(false);
    }
  };

  const handleImageLoad = (imageUrl) => {
    setLoadingImages(prev => ({ ...prev, [imageUrl]: false }));
  };

  const selectImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setLoadingImages(prev => ({ ...prev, [imageUrl]: true }));
  };

  const downloadProcessedImage = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <Helmet>
        <title>Face to Sticker - Create custom stickers effortlessly</title>
        <meta name="description" content="Use our advanced AI-powered tool to convert faces into custom stickers. Perfect for designers, social media, and more." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebApplication",
            "name": "Face to Sticker",
            "description": "AI-powered tool to convert faces into stickers",
            "url": "https://llmstock.com/facesticker",
            "applicationCategory": "ImageProcessingApplication",
            "operatingSystem": "Web"
          })}
        </script>
      </Helmet>
      <h1 className="text-4xl font-bold text-center mb-8">Face to Sticker</h1>

      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        <FalImageUploader onImageUpload={handleImageUpload} fileInputRef={fileInputRef} />

        <div className="mt-6">
          {originalImage && !processedImages.length && !isLoading && (
            <img src={originalImage} alt="Original Image" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
          )}
          {isLoading && (
            <div className="flex justify-center items-center h-16">
              <div className="loader"></div>
            </div>
          )}
          {!isLoading && selectedImage && (
            <div className="relative">
              {loadingImages[selectedImage] && (
                <div className="absolute inset-0 flex justify-center items-center bg-gray-200 bg-opacity-50">
                  <div className="loader"></div>
                </div>
              )}
              <img
                src={selectedImage}
                alt="Processed"
                onLoad={() => handleImageLoad(selectedImage)}
                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
              />
              <button
                onClick={() => downloadProcessedImage(selectedImage)}
                className="absolute top-2 right-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Download
              </button>
            </div>
          )}

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Prompt</label>
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="a person"
            />
          </div>

          <div className="mt-4">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              {isOpen ? 'Collapse Options' : 'Expand Options'}
            </button>
          </div>

          {isOpen && (
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Negative Prompt</label>
                <input
                  type="text"
                  value={negativePrompt}
                  onChange={(e) => setNegativePrompt(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="moustache, blurry, low resolution, low res, low quality"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Number of Inference Steps</label>
                <input
                  type="number"
                  value={numInferenceSteps}
                  onChange={(e) => setNumInferenceSteps(Number(e.target.value))}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  min="1"
                  max="100"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Guidance Scale</label>
                <Slider.Root
                  className="relative flex items-center select-none touch-none w-full h-5"
                  min={1}
                  max={20}
                  step={0.1}
                  value={[guidanceScale]}
                  onValueChange={(value) => setGuidanceScale(value[0])}
                >
                  <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                    <Slider.Range className="absolute bg-green-500 rounded-full h-full" />
                  </Slider.Track>
                  <Slider.Thumb className="block w-5 h-5 bg-green-500 rounded-full" />
                </Slider.Root>
                <p className="text-center text-sm text-gray-600 mt-1">Value: {guidanceScale}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Reference Strength</label>
                <Slider.Root
                  className="relative flex items-center select-none touch-none w-full h-5"
                  min={0.1}
                  max={1}
                  step={0.1}
                  value={[instantIdStrength]}
                  onValueChange={(value) => setInstantIdStrength(value[0])}
                >
                  <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                    <Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
                  </Slider.Track>
                  <Slider.Thumb className="block w-5 h-5 bg-blue-500 rounded-full" />
                </Slider.Root>
                <p className="text-center text-sm text-gray-600 mt-1">Value: {instantIdStrength}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">IP Adapter Weight</label>
                <Slider.Root
                  className="relative flex items-center select-none touch-none w-full h-5"
                  min={0.1}
                  max={1}
                  step={0.1}
                  value={[ipAdapterWeight]}
                  onValueChange={(value) => setIpAdapterWeight(value[0])}
                >
                  <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                    <Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
                  </Slider.Track>
                  <Slider.Thumb className="block w-5 h-5 bg-blue-500 rounded-full" />
                </Slider.Root>
                <p className="text-center text-sm text-gray-600 mt-1">Value: {ipAdapterWeight}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">IP Adapter Noise</label>
                <Slider.Root
                  className="relative flex items-center select-none touch-none w-full h-5"
                  min={0.1}
                  max={1}
                  step={0.1}
                  value={[ipAdapterNoise]}
                  onValueChange={(value) => setIpAdapterNoise(value[0])}
                >
                  <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                    <Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
                  </Slider.Track>
                  <Slider.Thumb className="block w-5 h-5 bg-blue-500 rounded-full" />
                </Slider.Root>
                <p className="text-center text-sm text-gray-600 mt-1">Value: {ipAdapterNoise}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Upscale</label>
                <select
                  value={upscale}
                  onChange={(e) => setUpscale(e.target.value === 'true')}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Upscale Steps</label>
                <input
                  type="number"
                  value={upscaleSteps}
                  onChange={(e) => setUpscaleSteps(Number(e.target.value))}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  min="1"
                  max="100"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Seed (optional)</label>
                <input
                  type="text"
                  value={seed}
                  onChange={(e) => setSeed(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter seed (optional)"
                />
              </div>
            </div>
          )}

<div className="mt-4 flex justify-center space-x-4">
            <button
              onClick={() => setIsApiKeyDialogOpen(true)}
              className="px-2 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              {LsIcons.Gear_svg_icon}
            </button>
            <button
              onClick={processImage}
              disabled={!originalImage || isProcessing}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
            >
              {isProcessing ? 'Processing...' : 'Generate Sticker'}
            </button>
          </div>

          {processedImages.length > 0 && (
            <div className="mt-6 grid grid-cols-2 gap-4">
              {processedImages.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image.url}
                    alt={`Processed ${index}`}
                    className="cursor-pointer"
                    onClick={() => selectImage(image.url)}
                    onLoad={() => handleImageLoad(image.url)}
                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                  />
                  {loadingImages[image.url] && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gray-200 bg-opacity-50">
                      <div className="loader"></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <FalApiKeyDialog
        apiKey={apiKey}
        setApiKey={setApiKey}
        isOpen={isApiKeyDialogOpen}
        setIsOpen={setIsApiKeyDialogOpen}
      />
    </div>
  );
};

export default FaceToSticker;

