import React, { useState, useRef, useEffect } from 'react';
import * as fal from "@fal-ai/serverless-client";
import ReactCompareImage from 'react-compare-image';
import FalApiKeyDialog from './FalApiKeyDialog';
import FalImageUploader from './FalImageUploader';
import { LsIcons } from './ui/LsIcons';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import { Compare } from './ui/compare';

const BackgroundRemover = () => {
  const [originalImage, setOriginalImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 新增状态 isLoading
  const [imagesLoaded, setImagesLoaded] = useState({ left: false, right: false });
  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "rembg" });
    const savedApiKey = localStorage.getItem('falApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, []);

  const handleImageUpload = async (file) => {
    setOriginalImage(URL.createObjectURL(file));
    setProcessedImage(null);
    uploadedFileRef.current = file;
  };

  const processImage = async () => {
    ReactGA.event({
        category: 'User',
        action: 'Clicked Background Remover'
      });
    if (!originalImage || !apiKey || !uploadedFileRef.current) {
      setIsApiKeyDialogOpen(true);
      return;
    }

    setIsProcessing(true);
    setIsLoading(true); // 设置加载状态为 true
    setImagesLoaded({ left: false, right: false }); // 重置加载状态

    try {
      fal.config({ credentials: apiKey });

      const uploadedUrl = await fal.storage.upload(uploadedFileRef.current);

      const result = await fal.subscribe("fal-ai/birefnet", {
        input: { image_url: uploadedUrl },
        logs: true,
        onQueueUpdate: (update) => {
          if (update.status === "IN_PROGRESS") {
            update.logs.map((log) => log.message).forEach(console.log);
          }
        },
      });

      setProcessedImage(result.image.url);
      console.log("Processed image URL:", result.image.url);
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleImageLoad = (side) => {
    setImagesLoaded((prev) => ({ ...prev, [side]: true }));
  };

  useEffect(() => {
    if (imagesLoaded.left && imagesLoaded.right) {
      setIsLoading(false); // 当两张图像都加载完成时，设置加载状态为 false
    }
  }, [imagesLoaded]);

  const downloadProcessedImage = () => {
    if (processedImage) {
      const link = document.createElement('a');
      link.href = processedImage;
      link.download = 'processed_image.png';
      link.click();
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
        <Helmet>
        <title>Background Remover Tool - Remove Image Backgrounds Easily</title>
        <meta name="description" content="Use our advanced AI-powered background remover tool to easily remove backgrounds from your images. Perfect for e-commerce, graphic design, and more." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebApplication",
            "name": "Background Remover Tool",
            "description": "AI-powered tool to remove image backgrounds",
            "url": "https://llmstock.com/rembg",
            "applicationCategory": "ImageProcessingApplication",
            "operatingSystem": "Web"
          })}
        </script>
      </Helmet>
      <h1 className="text-4xl font-bold text-center mb-8">Background Remover</h1>

      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        <FalImageUploader onImageUpload={handleImageUpload} fileInputRef={fileInputRef} />

        {originalImage && (
          <div className="mt-6">
            {processedImage === null ? (
              <img src={originalImage} alt="Original" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
            ) : (
              <>
                {isLoading && (
                  <div className="flex justify-center items-center h-64">
                    <div className="loader"></div> {/* 你可以使用任何加载动画或指示器 */}
                  </div>
                )}
                <div style={{ display: isLoading ? 'none' : 'block' }}>
                  <ReactCompareImage
                    leftImage={originalImage}
                    rightImage={processedImage}
                  />
                  {/* <Compare firstImage={originalImage} secondImage={processedImage} /> */}
                </div>
                <img
                  src={originalImage}
                  alt="Original"
                  style={{ display: 'none' }}
                  onLoad={() => handleImageLoad('left')}
                />
                <img
                  src={processedImage}
                  alt="Processed"
                  style={{ display: 'none' }}
                  onLoad={() => handleImageLoad('right')}
                />
              </>
            )}
          </div>
        )}

        <div className="mt-6 flex justify-center space-x-4">
          <button
            onClick={() => setIsApiKeyDialogOpen(true)}
            className="px-2 py-2 bg-blue-200 text-black rounded hover:bg-blue-400"
          >
            {LsIcons.Gear_svg_icon}
          </button>
          <button
            onClick={processImage}
            disabled={!originalImage || isProcessing}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
          >
            {isProcessing ? 'Processing...' : 'Remove Background'}
          </button>

          {processedImage && (
            <button
              onClick={downloadProcessedImage}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Download Processed Image
            </button>
          )}
        </div>
      </div>

      <FalApiKeyDialog
        apiKey={apiKey}
        setApiKey={setApiKey}
        isOpen={isApiKeyDialogOpen}
        setIsOpen={setIsApiKeyDialogOpen}
      />
    </div>
  );
};

export default BackgroundRemover;
