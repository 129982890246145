import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PostCard from './PostCard';
import ReactGA from "react-ga4";
import '../css/post-list.css';
import { PinLeftIcon, PinRightIcon, ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';

const PostsList = ({ selectedCategory }) => {
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Posts List" });
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const postsPerPage = 9;
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
  const containerRef = useRef(null);

  useEffect(() => {
    setPage(1);
  }, [selectedCategory]);

  useEffect(() => {
    fetchTotalPages();
    fetchPosts();
  }, [page, selectedCategory]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchTotalPages = async () => {
    try {
      const categoryFilter = selectedCategory && selectedCategory !== 'all' ? `&category.id=${selectedCategory}` : '';
      const response = await axios.get(`${apiUrl}/posts/count?${categoryFilter}`);
      const totalPosts = response.data;
      setTotalPages(Math.ceil(totalPosts / postsPerPage));
    } catch (error) {
      console.error('Error fetching total posts count:', error);
    }
  };

  const fetchPosts = async () => {
    try {
      const categoryFilter = selectedCategory && selectedCategory !== 'all' ? `&category.id=${selectedCategory}` : '';
      const start = (page - 1) * postsPerPage;
      const response = await axios.get(`${apiUrl}/posts?_limit=${postsPerPage}&_start=${start}${categoryFilter}&_sort=id:DESC`);
      setPosts(response.data);
      setHasMore(response.data.length === postsPerPage);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const loadPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    const maxPageNumbersToShow = isMobile ? 3 : 5;

    let startPage = Math.max(1, page - Math.floor(maxPageNumbersToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => loadPage(i)}
          className={`nav-button ${i === page ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="posts-container">
      <div className="posts-grid">
        {posts.map(post => (
          <PostCard key={post.id} post={post} />
        ))}
      </div>
      <div className="pagination-container">
        <div className="nav-button-group">
          <button
            onClick={() => loadPage(1)}
            className="nav-button"
            disabled={page === 1}
          >
            <PinLeftIcon />
          </button>
          <button
            onClick={() => loadPage(page - 1)}
            className="nav-button"
            disabled={page === 1}
          >
            <ArrowLeftIcon />
          </button>
          {renderPageNumbers()}
          <button
            onClick={() => loadPage(page + 1)}
            className="nav-button"
            disabled={!hasMore}
          >
            <ArrowRightIcon />
          </button>
          <button
            onClick={() => loadPage(totalPages)}
            className="nav-button"
            disabled={page === totalPages}
          >
            <PinRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostsList;
