import React, { useRef, useState, useEffect } from 'react';
import { LsIcons } from './ui/LsIcons';

const FalImageUploader = ({ onImageUpload }) => {
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const captureButtonRef = useRef(null);
  const [tipMessage, setTipMessage] = useState('');
  const [isCapturing, setIsCapturing] = useState(false);
  const streamRef = useRef(null);

  useEffect(() => {
    return () => {
      // Stop video stream and remove elements when component unmounts
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (videoRef.current) {
        document.body.removeChild(videoRef.current);
      }
      if (captureButtonRef.current) {
        document.body.removeChild(captureButtonRef.current);
      }
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onImageUpload(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file) {
      onImageUpload(file);
    }
  };

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClickCapture = async () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    if (isMobile) {
      const captureInput = document.createElement('input');
      captureInput.type = 'file';
      captureInput.accept = 'image/*';
      captureInput.capture = 'environment';
      captureInput.style.display = 'none';
      captureInput.onchange = handleFileChange;
      document.body.appendChild(captureInput);
      captureInput.click();
      document.body.removeChild(captureInput);
    } else {
      // Create video element
      const video = document.createElement('video');
      video.style.position = 'fixed';
      video.style.top = '50%';
      video.style.left = '50%';
      video.style.transform = 'translate(-50%, -50%)';
      video.style.zIndex = '10000';
      video.style.width = '80%';
      video.style.height = '80%';
      document.body.appendChild(video);
      videoRef.current = video;

      try {
        // Request camera permissions and stream video
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        streamRef.current = stream;
        video.srcObject = stream;
        await video.play();

        // Create capture button
        const captureButton = document.createElement('button');
        captureButton.textContent = 'Capture';
        captureButton.style.position = 'fixed';
        captureButton.style.bottom = '10%';
        captureButton.style.left = '50%';
        captureButton.style.transform = 'translateX(-50%)';
        captureButton.style.zIndex = '10001';
        captureButton.style.padding = '10px 20px';
        captureButton.style.fontSize = '16px';
        captureButton.style.backgroundColor = '#4CAF50';
        captureButton.style.color = '#fff';
        captureButton.style.border = 'none';
        captureButton.style.borderRadius = '5px';
        document.body.appendChild(captureButton);
        captureButtonRef.current = captureButton;

        setIsCapturing(true);

        // Capture event handler
        captureButton.onclick = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext('2d');
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert photo to file object
          canvas.toBlob((blob) => {
            const file = new File([blob], 'captured_image.png', { type: 'image/png' });
            onImageUpload(file);

            // Stop video stream
            stream.getTracks().forEach(track => track.stop());
            document.body.removeChild(video);
            document.body.removeChild(captureButton);
            setIsCapturing(false);
          }, 'image/png');
        };
      } catch (error) {
        console.error('Error accessing the camera', error);
        setTipMessage('Unable to access the camera. Please check your permissions.');
        document.body.removeChild(video);
        setIsCapturing(false);

        // Clear the tip message after 3 seconds
        setTimeout(() => {
          setTipMessage('');
        }, 3000);
      }
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className="border-dashed border-2 border-gray-400 p-4 text-center"
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />
      <p>Drag and drop an image here, or click to select one</p>
      <div className="mt-2 flex justify-center space-x-2">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleClickUpload}
          style={{ height: '38px' }}
        >
          Upload Image
        </button>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center"
          onClick={handleClickCapture}
          title="Capture a photo"
          disabled={isCapturing}
          style={{ height: '38px' }}
        >
          {LsIcons.Camera_svg_icon}
        </button>
      </div>
      {tipMessage && (
        <div className="mt-2 text-red-500">
          {tipMessage}
        </div>
      )}
    </div>
  );
};

export default FalImageUploader;
