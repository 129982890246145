// configTemplates.js
const APITemplates = [
    { name: 'gpt4o',
    url: '/playground?baseurl=https%3A%2F%2Fapi.openai.com%2Fv1&model=gpt-4o&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fplatform.openai.com%2Fapi-keys',
    icon: <img
    src={'/icons/openai.svg'}
    alt={'openai gpt4o'}
    className="h-12 w-12 transform transition duration-300 hover:scale-110"
/>},{ name: 'openrouter',
    url: '/playground?baseurl=https%3A%2F%2Fopenrouter.ai%2Fapi%2Fv1&model=google%2Fgemini-flash-1.5&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fopenrouter.ai%2Fsettings%2Fkeys',
    icon: <img
    src={'/icons/openrouter.jpg'}
    alt={'openrouter gemini-flash-1.5'}
    className="h-12 w-12 transform transition duration-300 hover:scale-110"
/>},
    { name: 'groq',
    url: '/playground?baseurl=https%3A%2F%2Fapi.groq.com%2Fopenai%2Fv1&model=llama-3.1-70b-versatile&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fconsole.groq.com%2Fkeys',
    icon: <img
    src={"/icons/groq.png"}
    alt={"groq llama3"}
    className="h-12 w-12 transform transition duration-300 hover:scale-110"
/> },
{ name: 'ollama',
url: '/playground?baseurl=http%3A%2F%2Flocalhost%3A11434%2Fv1%2F&model=gemma2&maxTokens=4096&temperature=0.7&apiKeyUrl=OLLAMA_ORIGINS%3D*%20should%20set%20in%20env&apiKey=ollama&notencrypted=1&isOllama=1',
icon: <img
src={"/icons/ollama.png"}
alt={"gemma2"}
className="h-12 w-12 transform transition duration-300 hover:scale-110"
/> },
{ name: 'nvidia',
url: '/playground?baseurl=https%3A%2F%2Fintegrate.api.nvidia.com%2Fv1&model=meta%2Fllama-3.1-405b-instruct&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fbuild.nvidia.com%2Fmeta%2Fllama-3_1-405b-instruct',
icon: <img
src={"/icons/nvidia.png"}
alt={"llama3.1-405b-instruct"}
className="h-12 w-12 transform transition duration-300 hover:scale-110"
/> },
    { name: 'deepseek2',
    url: '/playground?baseurl=https%3A%2F%2Fapi.deepseek.com%2Fv1&model=deepseek-chat&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fplatform.deepseek.com%2Fapi_keys',
    icon: <img
    src={"/icons/deepseek.png"}
    alt={"deepseek chat"}
    className="h-12 w-12 transform transition duration-300 hover:scale-110"
/> },
{ name: 'qwen2',
url: '/playground?baseurl=https%3A%2F%2Fdashscope.aliyuncs.com%2Fcompatible-mode%2Fv1&model=qwen2-72b-instruct&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fbailian.console.aliyun.com%2F%3FapiKey%3D1%23%2Fapi-key',
icon: <img
src={"/icons/qwen.png"}
alt={"qwen2-72b"}
className="h-12 w-12 transform transition duration-300 hover:scale-110"
/> },
// { name: 'kimi',
// url: '/playground?baseurl=https%3A%2F%2Fapi.moonshot.cn%2Fv1&model=moonshot-v1-128k&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fplatform.moonshot.cn%2Fconsole%2Fapi-keys',
// icon: <img
// src={"/icons/kimi.png"}
// alt={"moonshot-v1-128k"}
// className="h-12 w-12 transform transition duration-300 hover:scale-110"
// /> },
{ name: 'siliconflow',
url: '/playground?baseurl=https%3A%2F%2Fapi.siliconflow.cn%2Fv1&model=deepseek-ai%2FDeepSeek-Coder-V2-Instruct&maxTokens=4096&temperature=0.7&apiKeyUrl=https%3A%2F%2Fcloud.siliconflow.cn%2Faccount%2Fak',
icon: <img
src={"/icons/siliconflow.png"}
alt={"deepseek-ai/DeepSeek-Coder-V2-Instruct"}
className="h-12 w-12 transform transition duration-300 hover:scale-110"
/> },
  ];
  
  export default APITemplates;

