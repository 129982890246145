import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const exchangeToken = async (googleAccessToken) => {
  const response = await fetch('/exchange-token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ googleAccessToken }),
  });
  if (!response.ok) {
    throw new Error('Failed to exchange token');
  }
  const data = await response.json();
  return data.accessToken;
};

const LoginPage = ({ setIsAuthenticated, setUserInfo }) => {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log('Authorization Code Response:', codeResponse);
      const { code } = codeResponse;

      try {
        const response = await fetch('/glogin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Server Response:', data);

        const siteAccessToken = await exchangeToken(data.access_token);
        console.log('Site Access Token:', siteAccessToken);
        localStorage.setItem('accessToken', siteAccessToken);

        const { access_token, refresh_token, expiry_date, id_token, userInfo: googleUserInfo } = data;

        console.log('Google User Info:', googleUserInfo);

        Cookies.set('refreshToken', refresh_token, { expires: 30 });
        Cookies.set('expiryDate', expiry_date);
        Cookies.set('idToken', id_token);

        const apiUrl = '/api/checkinituser';
        const checkUserResponse = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${siteAccessToken}`,
          },
          body: JSON.stringify({
            email: googleUserInfo.email,
            source: 'google',
            sid: googleUserInfo.id,
            icon: googleUserInfo.picture,
          }),
        });

        if (!checkUserResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const checkUserData = await checkUserResponse.json();
        let mergedUserInfo = {
          ...googleUserInfo,
          lusername: checkUserData.user.lusername,
          luserid: checkUserData.user.luserid,
          icon: checkUserData.user.icon,
        };
        console.log('User exists:', checkUserData.user);
        localStorage.setItem('luserid', checkUserData.user.luserid);
       
        Cookies.set('userInfo', JSON.stringify(mergedUserInfo), { expires:  parseInt(process.env.REACT_APP_CHAT_TYPE, 10) });

        setIsAuthenticated(true);
        setUserInfo(mergedUserInfo);

        navigate('/');
      } catch (error) {
        console.error('Error handling Google auth:', error);
      }
    },
    onError: (error) => {
      console.error('Login Error:', error);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/userinfo.profile',
    accessType: 'offline',
    prompt: 'consent',
  });

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div class="flex items-center justify-center h-screen dark:bg-gray-800">
    <button onClick={login} className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150">
        <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"/>
        <span>Login with Google</span>
    </button>
</div>
    </div>
  );
};

export default LoginPage;
