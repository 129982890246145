import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import '../css/radixdialog.css';
import "../css/imagecarddialog.css";
import { LsIcons } from './ui/LsIcons';

const ExampleImageCard = ({ image, prompt, negativePrompt, onRegenerate }) => {
  const [showImage, setShowImage] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [dialogSize, setDialogSize] = useState({ width: 'auto', height: 'auto' });

  useEffect(() => {
    if (showImage) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const imgWidth = img.width;
        const imgHeight = img.height;

        let width, height;

        if (imgWidth > screenWidth || imgHeight > screenHeight) {
          const widthRatio = screenWidth / imgWidth;
          const heightRatio = screenHeight / imgHeight;
          const ratio = Math.min(widthRatio, heightRatio);
          width = imgWidth * ratio;
          height = imgHeight * ratio;
        } else {
          width = imgWidth;
          height = imgHeight;
        }

        setDialogSize({ width, height });
      };
      img.onerror = () => {
        console.error('Image failed to load:', image);
      };
    }
  }, [showImage, image]);

  return (
    <div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:translate-y-[-10px] hover:shadow-lg">
        <img
          src={image}
          className="card-img-top cursor-pointer"
          alt="Example"
          onClick={() => setShowImage(true)}
        />
        <div className="card-body">
          <div className="flex justify-between items-center">
            <button
              className="m-2 btn btn-info"
              onClick={() => setShowInfo(true)}
            >
               {LsIcons.Text2Image_info_icon}
            </button>
            <button
              className="m-2 btn btn-secondary"
              onClick={() => onRegenerate(prompt, negativePrompt)}
            >
              {LsIcons.AIChatComponent_regenerate_svg_icon}
            </button>
          </div>
        </div>
      </div>

      {/* Info Dialog */}
      {showInfo && (
        <Dialog.Root open={showInfo} onOpenChange={setShowInfo}>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className="DialogTitle">Prompt Info</Dialog.Title>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <label className="font-bold">Prompt</label>
                <button className="Button small" onClick={() => navigator.clipboard.writeText(prompt || '')}>
                  {LsIcons.Text2Image_copy_svg}
                </button>
              </div>
              <textarea
                className="border p-2 rounded w-full"
                rows={Math.min(Math.ceil((prompt || '').length / 45), 6)}
                readOnly
                value={prompt || ''}
              />
              <div>
                <label className="font-bold">Negative Prompt</label>
                <textarea
                  className="border p-2 rounded w-full"
                  rows={Math.min(Math.ceil((negativePrompt || '').length / 45), 6)}
                  readOnly
                  value={negativePrompt || ''}
                />
              </div>
            </div>
            <Dialog.Close asChild>
              <button className="Button green mt-4">
                Close
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Root>
      )}

      {/* Image Dialog */}
      {showImage && (
        <Dialog.Root open={showImage} onOpenChange={setShowImage}>
          <Dialog.Overlay className="DialogOverlayImg" />
          <Dialog.Content
            className="DialogImageContent"
            style={{ width: dialogSize.width, height: dialogSize.height }}
          >
            <TransformWrapper>
              <TransformComponent>
                <img src={image} alt="Example" className="w-full h-auto rounded-lg" />
              </TransformComponent>
            </TransformWrapper>
            <Dialog.Close asChild>
              <button className="DialogImageCloseButton">&#x2715;</button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Root>
      )}
    </div>
  );
};

export default ExampleImageCard;
