import React, { useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

const ImagePreview = ({ imageUrl, altText, isOpen, onClose }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY * -0.01;
    setScale(prevScale => Math.max(0.1, Math.min(5, prevScale + delta)));
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 dark:bg-opacity-90" />
      <Dialog.Content 
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 rounded-lg overflow-hidden"
        style={{
          width: '80vw',
          height: '80vh',
          maxHeight: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
        }}
      >
        <div 
          className="relative w-full h-full overflow-hidden bg-white bg-opacity-20 dark:bg-gray-800 dark:bg-opacity-20 rounded-lg"
          onWheel={handleWheel}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
        >
          <img 
            ref={imageRef}
            src={imageUrl}
            alt={altText}
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
              transition: isDragging ? 'none' : 'transform 0.1s',
              maxWidth: 'none',
              maxHeight: 'none',
            }}
            className="w-full h-full object-contain"
            draggable="false"
          />
        </div>
        <Dialog.Close asChild>
          <button 
            className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-800 text-black dark:text-white rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none"
            style={{
              zIndex: 10
            }}
          >
            ×
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ImagePreview;
