import React, { useState, useEffect } from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import '../css/blog-category.css';
import { LsIcons } from './ui/LsIcons';

const CategoryFilter = ({ categories, selectedCategory, onCategoryChange }) => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 768);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsCollapsed(false);
        setIsMobile(false);
      } else {
        setIsCollapsed(true);
        setIsMobile(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="category-filter-wrapper">
      {isMobile && (
        <button className="toggle-button" onClick={handleToggle}>
          Categories <span className="icon">{LsIcons.Mixer_vertical_svg_icon}</span>
        </button>
      )}
      {(isMobile && !isCollapsed) || !isMobile ? (
        <div className="category-filter">
          <RadioGroup.Root
            value={selectedCategory || 'all'}
            onValueChange={onCategoryChange}
            className="category-options"
          >
            <RadioGroup.Item value="all" className="radio-button">
              All
            </RadioGroup.Item>
            {categories.map((category) => (
              <RadioGroup.Item key={category.id} value={category.id.toString()} className="radio-button">
                {category.name}
              </RadioGroup.Item>
            ))}
          </RadioGroup.Root>
        </div>
      ) : null}
    </div>
  );
};

export default CategoryFilter;
