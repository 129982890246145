import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import stockData from '../data/company_tickers.json'; // 导入 JSON 文件

const StockSearch = ({ onSetStock, buttonText = 'Change stock' }) => { // 接收 onSetStock 和 buttonText 作为 props
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return [];
    }

    const filteredSuggestions = Object.values(stockData).filter(stock =>
      (stock.title && stock.title.toLowerCase().includes(inputValue)) ||
      (stock.ticker && stock.ticker.toLowerCase().includes(inputValue))
    );

    return filteredSuggestions.slice(0, 10); // 提示不超过10个
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.ticker;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.title} ({suggestion.ticker})
    </div>
  );

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const inputProps = {
    placeholder: 'Input stock symbol for example AAPL',
    value,
    onChange,
  };

  const handleSetStock = () => {
    onSetStock(value); // 调用父组件传入的 onSetStock 函数
  };

  return (
    <div className="flex flex-col sm:flex-row items-stretch justify-center gap-2 w-full max-w-2xl mx-auto">
      <div className="relative flex-grow">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...inputProps,
            id: 'search-bar',
            className: 'px-4 py-2 w-full rounded-md outline-none bg-white border',
          }}
          theme={{
            container: 'relative w-full',
            suggestionsContainer: 'absolute z-10 w-full bg-white shadow-lg rounded mt-1',
            suggestion: 'px-4 py-2 cursor-pointer',
            suggestionHighlighted: 'bg-gray-200',
          }}
        />
      </div>
      <button
        onClick={handleSetStock}
        className="flex justify-center items-center px-6 py-2 bg-black border-black text-white fill-white active:scale-95 duration-100 border will-change-transform overflow-hidden relative rounded-md transition-all disabled:opacity-70 sm:w-auto"
      >
        <div className="relative flex items-center">
          <div className="h-3 w-3 absolute left-0 transition-all">
            <svg className="opacity-0 animate-spin w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <span className="text-sm font-semibold whitespace-nowrap truncate mx-auto">
            {buttonText}
          </span>
        </div>
      </button>
    </div>
  );
};

export default StockSearch;
