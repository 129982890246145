import React, { useEffect, useRef, useCallback, useState } from 'react';
import CodeCopyBtn from './codeCopyBtn';
import mermaid from 'mermaid';
import "../../css/mermaid.css"
import { LsIcons } from './LsIcons';
import "../../css/post-detail.css"

const CodePre = ({ children }) => {
    const mermaidRef = useRef(null);
    const [mermaidSvg, setMermaidSvg] = useState('');
    const [error, setError] = useState(null);
    let code = '';
    let language = '';
    if (children && children.props && children.props.children) {
        code = children.props.children;
        const match = /language-(\w+)/.exec(children.props.className || '');
        language = match ? match[1] : '';
    }

    const renderMermaidDiagram = useCallback(() => {
        if (language === 'mermaid' && mermaidRef.current) {
            const id = `mermaid-${Math.random().toString(36).substr(2, 9)}`;
            mermaid.render(id, code)
                .then(({ svg }) => {
                    setMermaidSvg(svg);
                    setError(null);
                })
                .catch(err => {
                    console.error('Mermaid rendering failed:', err);
                    setError(err.message);
                });
        }
    }, [language, code]);

    useEffect(() => {
        mermaid.initialize({ startOnLoad: false, theme: 'default' });
    }, []);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            renderMermaidDiagram();
        }, 500); // Wait for 500ms after last content change

        return () => clearTimeout(debounceTimer);
    }, [code, renderMermaidDiagram]);

    const handleRunHTML = () => {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(code);
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(code).then(() => {
            console.log('Code copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy code: ', err);
        });
    };

    if (language === 'mermaid') {
        return (
            <div className="mermaid-container">
                    <div className="button-container flex flex-wrap justify-left items-center text-black">
                        <span className="flex m-2 cursor-pointer" onClick={handleCopyCode}>{LsIcons.Chat_copy_svg_icon} {language}</span>
                    </div>
                <div ref={mermaidRef} className="mermaid">
                    {mermaidSvg ? (
                        <div dangerouslySetInnerHTML={{ __html: mermaidSvg }} />
                    ) : (
                        <pre className="custom-pre">{code}</pre>
                    )}
                </div>
                {error && <div className="error">Error rendering Mermaid: {error}</div>}
            </div>
        );
    }

    return (
        <div className="code-copy-container">
            <div className="button-container flex flex-wrap justify-left items-center">
                <CodeCopyBtn code={code} />
                <span className="flex m-2">{language}</span>
                {language === 'html' && (
                    <button onClick={handleRunHTML} className="run-html-btn">Run HTML</button>
                )}
            </div>
            <pre className="custom-pre">
                {children}
            </pre>
        </div>
    );
};

export default CodePre;
