import React from 'react';
import { Link } from 'react-router-dom';
import '../css/post-card.css';

const PostCard = ({ post }) => {
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
  const defaultImageUrl = '/images/default_blog.jpg';

  return (
    <Link to={`/post/${post.id}`} className="block post-card h-full">
      <div className="bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:translate-y-[-10px] hover:shadow-lg h-full flex flex-col">
        <div className="post-card-img-container">
          <img
            src={post.cover && post.cover.length > 0 ? `${apiUrl}${post.cover[0].url}` : defaultImageUrl}
            alt={post.title}
            className="post-card-img transition-opacity hover:opacity-90 w-full h-48 object-cover"
          />
        </div>
        <div className="p-4 post-card-content flex flex-col flex-grow">
          <h2 className="text-xl font-bold mb-2 line-clamp-2">
            {post.title}
          </h2>
          <p className="text-gray-700 mb-2 flex-grow line-clamp-3">{post.description}</p>
          <div className="flex items-center mt-auto">
            <p className="text-gray-500">{new Date(post.date).toLocaleDateString()}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PostCard;
