import React from 'react';
import { Link } from 'react-router-dom';
import { BentoGridItem } from './ui/bento-grid';

interface FeatureCardProps {
  title: string;
  description: string;
  imageSrc: string;
  linkTo: string;
  className?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, imageSrc, linkTo, className }) => {
  return (
    <Link to={linkTo} className="block">
      <BentoGridItem
        className={className}
        title={title}
        description={description}
        header={
          <div className="w-full h-48 relative">
            <img
              src={imageSrc}
              alt={title}
              loading="lazy"
              className="absolute inset-0 w-full h-full object-cover rounded-t-xl"
            />
          </div>
        }
      />
    </Link>
  );
};

export default FeatureCard;
