import React from "react";
import "./codeCopyBtn.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

export default function CodeCopyBtn({ code }) {
    const [copyOk, setCopyOk] = React.useState(false);

    const iconColor = copyOk ? '#0af20a' : '#ddd';
    const icon = copyOk ? faCheckSquare : faCopy;

    const handleClick = () => {
        navigator.clipboard.writeText(code);
        setCopyOk(true);
        setTimeout(() => {
            setCopyOk(false);
        }, 500);
    };

    return (
        <button className="code-copy-btn" onClick={handleClick} aria-label="Copy to clipboard">
            <FontAwesomeIcon icon={icon} color={iconColor} />
        </button>
    );
}
