import React, { useState, useRef, useEffect } from 'react';
import * as fal from "@fal-ai/serverless-client";
import ReactCompareImage from 'react-compare-image';
import FalApiKeyDialog from './FalApiKeyDialog';
import FalImageUploader from './FalImageUploader';
import { LsIcons } from './ui/LsIcons';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import * as Slider from '@radix-ui/react-slider';

const CreativeUpscaler = () => {
    const [originalImage, setOriginalImage] = useState(null);
    const [processedImage, setProcessedImage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
    const [modelType, setModelType] = useState('SDXL');
    const [scale, setScale] = useState(2);
    const [creativity, setCreativity] = useState(0.5);
    const [prompt, setPrompt] = useState('masterpiece');
    const [negativePrompt, setNegativePrompt] = useState('blurry, low resolution, bad, ugly, low quality, pixelated, interpolated, compression artifacts, noisey, grainy');
    const [shapePreservation, setShapePreservation] = useState(0.25);
    const [guidanceScale, setGuidanceScale] = useState(7.5);
    const [numInferenceSteps, setNumInferenceSteps] = useState(20);
    const [isLoading, setIsLoading] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState({ left: false, right: false });
    const fileInputRef = useRef(null);
    const uploadedFileRef = useRef(null);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "creativeupscaler" });
        const savedApiKey = localStorage.getItem('falApiKey');
        if (savedApiKey) {
            setApiKey(savedApiKey);
        }
    }, []);

    const handleImageUpload = async (file) => {
        setOriginalImage(URL.createObjectURL(file));
        setProcessedImage(null);
        uploadedFileRef.current = file;
        setImagesLoaded({ left: false, right: false });
        setIsProcessing(false);
        setIsLoading(false);
    };

    const processImage = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Creative Upscale'
        });
        if (!originalImage || !apiKey || !uploadedFileRef.current) {
            setIsApiKeyDialogOpen(true);
            return;
        }

        setIsProcessing(true);
        setIsLoading(true);
        setImagesLoaded({ left: false, right: false });

        try {
            fal.config({ credentials: apiKey });

            const uploadedUrl = await fal.storage.upload(uploadedFileRef.current);

            const result = await fal.subscribe("fal-ai/creative-upscaler", {
                input: {
                    model_type: modelType,
                    image_url: uploadedUrl,
                    scale: scale,
                    creativity: creativity,
                    detail: 1,
                    shape_preservation: shapePreservation,
                    prompt_suffix: `${prompt} high quality, highly detailed, high resolution, sharp`,
                    negative_prompt: negativePrompt,
                    seed: 42,
                    guidance_scale: guidanceScale,
                    num_inference_steps: numInferenceSteps,
                    enable_safety_checks: true,
                    additional_lora_scale: 1
                },
                logs: true,
                onQueueUpdate: (update) => {
                    if (update.status === "IN_PROGRESS") {
                        update.logs.map((log) => log.message).forEach(console.log);
                    }
                },
            });

            setProcessedImage(result.image.url);
            console.log("Processed image URL:", result.image.url);
        } catch (error) {
            console.error("Error processing image:", error);
            setIsLoading(false);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleImageLoad = (side) => {
        setImagesLoaded((prev) => ({ ...prev, [side]: true }));
    };

    useEffect(() => {
        if (imagesLoaded.left && imagesLoaded.right) {
            setIsLoading(false);
        }
    }, [imagesLoaded]);

    const downloadProcessedImage = () => {
        if (processedImage) {
            window.open(processedImage, '_blank');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <Helmet>
                <title>Image Upscale Tool - Upscale image with best quality easily</title>
                <meta name="description" content="Use our advanced AI-powered image upscale tool to easily upscale your images. Perfect for e-commerce, graphic design, and more." />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebApplication",
                        "name": "Image Upscale Tool",
                        "description": "AI-powered tool to upscale your images",
                        "url": "https://llmstock.com/creative",
                        "applicationCategory": "ImageProcessingApplication",
                        "operatingSystem": "Web"
                    })}
                </script>
            </Helmet>
            <h1 className="text-4xl font-bold text-center mb-8">Creative Upscaler</h1>

            <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
                <FalImageUploader onImageUpload={handleImageUpload} fileInputRef={fileInputRef} />

                <div className="mt-6">
                    {originalImage && !processedImage && !isLoading && (
                        <img src={originalImage} alt="Original Image" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                    )}
                    {isLoading && (
                        <div className="flex justify-center items-center h-16">
                            <div className="loader"></div>
                        </div>
                    )}
                    {!isLoading && processedImage && (
                        <ReactCompareImage
                            leftImage={originalImage}
                            rightImage={processedImage}
                        />
                    )}
                    {processedImage && (
                        <>
                            <img
                                src={originalImage}
                                alt="Original"
                                style={{ display: 'none' }}
                                onLoad={() => handleImageLoad('left')}
                            />
                            <img
                                src={processedImage}
                                alt="Processed"
                                style={{ display: 'none' }}
                                onLoad={() => handleImageLoad('right')}
                            />
                        </>
                    )}
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Model Type</label>
                        <select
                            value={modelType}
                            onChange={(e) => setModelType(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            <option value="SDXL">SDXL</option>
                            <option value="SD_1_5">SD 1.5</option>
                        </select>
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Scale</label>
                        <select
                            value={scale}
                            onChange={(e) => setScale(Number(e.target.value))}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        >
                            <option value={2}>2x</option>
                            <option value={4}>4x</option>
                        </select>
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Creativity</label>
                        <Slider.Root
                            className="relative flex items-center select-none touch-none w-full h-5"
                            min={0.1}
                            max={1}
                            step={0.1}
                            value={[creativity]}
                            onValueChange={(value) => setCreativity(value[0])}
                        >
                            <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                                <Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
                            </Slider.Track>
                            <Slider.Thumb className="block w-5 h-5 bg-blue-500 rounded-full" />
                        </Slider.Root>
                        <p className="text-center text-sm text-gray-600 mt-1">Value: {creativity}</p>
                    </div>

                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Prompt</label>
                        <input
                            type="text"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="masterpiece"
                        />
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Negative Prompt</label>
                        <input
                            type="text"
                            value={negativePrompt}
                            onChange={(e) => setNegativePrompt(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="blurry, low resolution, bad, ugly, low quality, pixelated, interpolated, compression artifacts, noisey, grainy"
                        />
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Shape Preservation</label>
                        <Slider.Root
                            className="relative flex items-center select-none touch-none w-full h-5"
                            min={0.1}
                            max={1}
                            step={0.1}
                            value={[shapePreservation]}
                            onValueChange={(value) => setShapePreservation(value[0])}
                        >
                            <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                                <Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
                            </Slider.Track>
                            <Slider.Thumb className="block w-5 h-5 bg-blue-500 rounded-full" />
                        </Slider.Root>
                        <p className="text-center text-sm text-gray-600 mt-1">Value: {shapePreservation}</p>
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Guidance Scale</label>
                        <Slider.Root
                            className="relative flex items-center select-none touch-none w-full h-5"
                            min={1}
                            max={20}
                            step={0.1}
                            value={[guidanceScale]}
                            onValueChange={(value) => setGuidanceScale(value[0])}
                        >
                            <Slider.Track className="bg-gray-200 relative flex-grow rounded-full h-1">
                                <Slider.Range className="absolute bg-green-500 rounded-full h-full" />
                            </Slider.Track>
                            <Slider.Thumb className="block w-5 h-5 bg-green-500 rounded-full" />
                        </Slider.Root>
                        <p className="text-center text-sm text-gray-600 mt-1">Value: {guidanceScale}</p>
                    </div>
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Number of Inference Steps</label>
                        <input
                            type="number"
                            value={numInferenceSteps}
                            onChange={(e) => setNumInferenceSteps(Number(e.target.value))}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            min="1"
                            max="100"
                        />
                    </div>
                    <div className="mt-4 flex justify-center space-x-4">
                        <button
                            onClick={() => setIsApiKeyDialogOpen(true)}
                            className="px-2 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                        >
                            {LsIcons.Gear_svg_icon}
                        </button>
                        <button
                            onClick={processImage}
                            disabled={!originalImage || isProcessing}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                        >
                            {isProcessing ? 'Processing...' : 'Upscale Image'}
                        </button>
                        {processedImage && (
                            <button
                                onClick={downloadProcessedImage}
                                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                            >
                                Download Processed Image
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <FalApiKeyDialog
                apiKey={apiKey}
                setApiKey={setApiKey}
                isOpen={isApiKeyDialogOpen}
                setIsOpen={setIsApiKeyDialogOpen}
            />
        </div>
    );
};

export default CreativeUpscaler;
