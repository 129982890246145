import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import * as Slider from '@radix-ui/react-slider';
import "../css/slider.css";
import { LsIcons } from "@/components/ui/LsIcons";

const APISettings = ({
  baseurl, setBaseurl,
  model, setModel,
  apiKey, setApiKey,
  maxTokens, setMaxTokens,
  temperature, setTemperature,
  asSidebar = true,
  isOllama = "0",
  setIsSettingsOpen,
  children,
  ...props
}) => {
  const [models, setModels] = useState([]);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const [fetchModelsError, setFetchModelsError] = useState(null);
  const [showSliders, setShowSliders] = useState(asSidebar || window.innerWidth > 768);

  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };

  const fetchModels = async () => {
    if (!apiKey) {
      return;
    }
    setIsLoadingModels(true);
    setFetchModelsError(null);
    try {
      let url;
      if (isOllama === "1") {
        const { protocol, host } = new URL(baseurl);
        url = `${protocol}//${host}/api/tags`;
      } else {
        url = `${baseurl}/models`;
      }

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      });

      if (!response.ok) {
        const text = await response.text();
        console.error(`HTTP error! status: ${response.status}, text: ${text}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        console.error("Invalid content type, expected application/json");
      }

      const data = await response.json();
      if (isOllama === "1") {
        setModels(data.models.map(model => ({ value: model.name.split(':')[0], label: model.name.split(':')[0] })));
      } else {
        setModels(data.data.map(model => ({ value: model.id, label: model.id })));
      }

    } catch (error) {
      console.error('Error fetching models:', error);
      setFetchModelsError("Error fetching models.");
      setModels([]);
    } finally {
      setIsLoadingModels(false);
    }
  };

  useEffect(() => {
    fetchModels();
  }, [baseurl, apiKey, isOllama]);

  useEffect(() => {
    const handleResize = () => {
      if (!asSidebar) {
        setShowSliders(window.innerWidth > 768);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [asSidebar]);

  const containerClass = asSidebar
    ? "relative h-dvh w-64 overflow-x-hidden overflow-y-auto border-l border-slate-300 bg-slate-50 py-8 dark:border-slate-700 dark:bg-slate-900 sm:w-74"
    : "flex flex-col p-2 border border-slate-300 bg-slate-50 dark:border-slate-700 dark:bg-slate-900 rounded-lg";

  return (
    <div className={asSidebar ? "flex flex-row-reverse" : ""}>
      <aside className={asSidebar ? "flex" : "w-full"}>
        <div className={containerClass}>
          <div className={asSidebar ? "absolute left-2 top-4 text-gray-800 hover:text-blue-200" : "hidden"}
            onClick={handleCloseSettings}>
            {LsIcons.SideMenu_close_svg_icon}
          </div>
          <div className="mb-2 flex items-center justify-between px-2 text-slate-800 dark:text-slate-200">
            <h2 className="text-lg font-medium">Settings stored locally</h2>
            <button
              onClick={() => setShowSliders(prev => !prev)}
              className="text-sm text-blue-600 dark:text-blue-400"
            >
              {showSliders ? LsIcons.Mixer_vertical_svg_icon : LsIcons.Mixer_vertical_svg_icon}
            </button>
          </div>

          <div className="px-2 py-2 text-slate-800 dark:text-slate-200">
            <label htmlFor="base-url" className="block text-sm font-medium">Base URL, API key & Model</label>
            <input
              type="url"
              id="base-url"
              placeholder="base_Url"
              className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
              value={baseurl}
              onChange={(e) => setBaseurl(e.target.value)}
            />
            <input
              type="password"
              placeholder="apiKey"
              id="api-key"
              className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <input
              type="text"
              id="model"
              placeholder="model"
              className="mt-1 w-full border-t-0 border-r-0 border-l-0 border-b-2 border-slate-300 bg-transparent py-1 text-sm focus:outline-none focus:border-blue-600 dark:border-slate-700 dark:focus:border-blue-600"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            />
          </div>

          <div className="px-2 py-2 text-slate-800 dark:text-slate-200">
            <label htmlFor="available-models" className="block text-sm font-medium">Available Models</label>
            <div className="flex items-center">
              <Select
                isLoading={isLoadingModels}
                options={models}
                value={models.find(m => m.value === model)}
                onChange={(selectedOption) => setModel(selectedOption ? selectedOption.value : '')}
                onCreateOption={(inputValue) => setModel(inputValue)}
                className="mt-1 w-full"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    zIndex: 50,
                    borderBottom: '1px solid #cbd5e0',
                    background: 'transparent',
                    boxShadow: 'none',
                    '&:hover': {
                      borderBottom: '1px solid #4299e1',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    background: '#f7fafc',
                    border: '1px solid #e2e8f0',
                    borderRadius: '0.25rem',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    background: state.isFocused ? '#e2e8f0' : 'transparent',
                    color: '#2d3748',
                  }),
                }}
                placeholder={fetchModelsError || "Select a model"}
              />
            </div>
          </div>

          {showSliders && (
            <>
              <div
                className="flex flex-row flex-wrap gap-2 mb-2 justify-left items-center px-2 py-2 text-slate-800 dark:text-slate-200">
                <label htmlFor="max-tokens" className="block text-sm font-medium">Max Tokens</label>
                <Slider.Root
                  className={asSidebar ? "SliderRootSm" : "SliderRoot"}
                  value={[maxTokens]}
                  max={4096}
                  step={1}
                  onValueChange={(value) => setMaxTokens(value[0])}
                >
                  <Slider.Track className={asSidebar ? "SliderTrackSm" : "SliderTrack"}>
                    <Slider.Range className="SliderRange" />
                  </Slider.Track>
                  <Slider.Thumb className={asSidebar ? "SliderThumbSm" : "SliderThumb"} aria-label="Max tokens" />
                </Slider.Root>
                <span className="ml-2">{maxTokens}</span>
              </div>

              <div
                className="flex flex-row flex-wrap gap-2 mb-2 justify-left items-center px-2 py-2 text-slate-800 dark:text-slate-200">
                <label htmlFor="temperature" className="block text-sm font-medium">Temperature</label>
                <Slider.Root
                  className={asSidebar ? "SliderRootSm" : "SliderRoot"}
                  value={[temperature]}
                  max={2}
                  step={0.1}
                  onValueChange={(value) => setTemperature(value[0])}
                >
                  <Slider.Track className={asSidebar ? "SliderTrackSm" : "SliderTrack"}>
                    <Slider.Range className="SliderRange" />
                  </Slider.Track>
                  <Slider.Thumb className={asSidebar ? "SliderThumbSm" : "SliderThumb"} aria-label="Temperature" />
                </Slider.Root>
                <span className="ml-2">{temperature}</span>
              </div>
            </>
          )}
          {children}
        </div>
      </aside>
    </div>
  );
};

export default APISettings;
