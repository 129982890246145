import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './PassphraseInput.css'; // 添加自定义样式

const PassphraseInput = ({ onComplete }) => {
    const [passphrase, setPassphrase] = useState('');
    const phraseLength = 4;

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= phraseLength) {
            setPassphrase(value);
            if (value.length === phraseLength) {
                onComplete(value);
                unmountComponent();
            }
        }
    };

    const handleButtonClick = (num) => {
        const newPassphrase = passphrase + num;
        if (newPassphrase.length <= phraseLength) {
            setPassphrase(newPassphrase);
            if (newPassphrase.length === phraseLength) {
                onComplete(newPassphrase);
                unmountComponent();
            }
        }
    };

    const handleDelete = () => {
        setPassphrase(passphrase.slice(0, -1));
    };

    const unmountComponent = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('passphrase-modal'));
    };

    return ReactDOM.createPortal(
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Enter Passphrase</h2>
                </div>
                <div className="modal-body">
                    <input
                        type="password"
                        value={passphrase}
                        onChange={handleChange}
                        maxLength={phraseLength}
                        style={{ display: 'block', margin: '0 auto', marginBottom: '20px', textAlign: 'center' }}
                    />
                    <div className="passphrase-dots">
                        {Array.from({ length: phraseLength }).map((_, index) => (
                            <span key={index} className="dot">
                                {index < passphrase.length ? '•' : ''}
                            </span>
                        ))}
                    </div>
                    <div className="keypad">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <button key={index} onClick={() => handleButtonClick(index)}>
                                {index}
                            </button>
                        ))}
                        <button onClick={handleDelete}>←</button>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('passphrase-modal')
    );
};

export default PassphraseInput;