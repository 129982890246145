import { useEffect, useRef } from 'react';
import { franc } from 'franc';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mapLangCodeToVoiceLang = (langCode) => {
    switch (langCode) {
        case 'cmn': return 'zh-CN';   // 简体中文（中国大陆）
        case 'rus': return 'ru-RU';         // 俄语（俄罗斯）
        case 'jpn': return 'ja-JP';         // 日语（日本）
        case 'kor': return 'ko-KR';         // 韩语（韩国）
        case 'fra': return 'fr-FR';         // 法语（法国）
        case 'deu': return 'de-DE';         // 德语（德国）
        case 'spa': return 'es-ES';         // 西班牙语（西班牙）
        case 'ara': return 'ar-AE';         // 阿拉伯语（阿联酋）
        case 'vi':  return 'vi-VN';         // 越南语（越南）
        case 'id':  return 'id-ID';         // 印尼语（印尼）
        case 'eng': return 'en-US';         // 英语（美国）
        default:    return 'zh-CN';   // 默认为中文
    }
};

const sentenceDelimiter = /[。？！;.!?]+[\s]*|$/;

const splitTextByLanguage = (text) => {
    return text.split(sentenceDelimiter).map(sentence => sentence.trim()).filter(sentence => sentence.length > 0);
};

const useTextToSpeech = (onEndCallback) => {
    const speechSynthesisUtterance = useRef(null);
    const isSpeaking = useRef(false);
    const isSupported = useRef(false);

    useEffect(() => {
        if ('speechSynthesis' in window) {
            isSupported.current = true;
            speechSynthesisUtterance.current = new SpeechSynthesisUtterance();

            const setVoices = () => {
                const voices = window.speechSynthesis.getVoices();
                if (voices.length > 0) {
                    speechSynthesisUtterance.current.voice = voices[0]; // 默认选择第一个声音
                }
            };

            setVoices();
            window.speechSynthesis.onvoiceschanged = setVoices;

            return () => {
                window.speechSynthesis.onvoiceschanged = null;
            };
        } else {
            // toast.error('当前浏览器不支持语音合成功能，请切换到桌面版 Chrome 或 Edge 浏览器。');
        }
    }, []);

    const speak = (text) => {
        if (!isSupported.current) {
            toast.error('Buildin TTS not supported on your current browser switch to chrome or edge desktop.');
            return;
        }

        window.speechSynthesis.cancel(); // 确保取消当前所有朗读
        isSpeaking.current = true;

        const segments = splitTextByLanguage(text);
        let index = 0;

        const speakNext = () => {
            if (index < segments.length && isSpeaking.current) {
                const segment = segments[index];
                const languageCode = franc(segment, { minLength: 3 }); // 提高语言检测的准确性
                const language = mapLangCodeToVoiceLang(languageCode);
                const voices = window.speechSynthesis.getVoices();
                const voice = voices.find(voice => voice.lang.startsWith(language));

                // console.log(`Speaking segment ${index + 1}/${segments.length}: ${segment}`);
                // console.log(`Detected language: ${languageCode}, Voice language: ${voice?.lang}`);

                if (voice) {
                    speechSynthesisUtterance.current = new SpeechSynthesisUtterance(segment); // 重新实例化以清除之前的设置
                    speechSynthesisUtterance.current.voice = voice;
                    speechSynthesisUtterance.current.lang = voice.lang;
                    speechSynthesisUtterance.current.onend = () => {
                        index++;
                        speakNext();
                    };
                    speechSynthesisUtterance.current.onerror = (e) => {
                        console.error('TTS error:', e);
                        isSpeaking.current = false;
                    };
                    window.speechSynthesis.speak(speechSynthesisUtterance.current);
                } else {
                    console.log("No voice found for language: ", language);
                    index++;
                    speakNext();
                }
            } else {
                if (onEndCallback) {
                    onEndCallback();
                }
                isSpeaking.current = false;
            }
        };

        speakNext();
    };

    const stopSpeak = () => {
        if (!isSupported.current) return;

        window.speechSynthesis.cancel();
        isSpeaking.current = false;
    };

    return { speak, stopSpeak };
};

export default useTextToSpeech;
