import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { LsIcons } from './ui/LsIcons';
import "../css/radixdialog.css";

const ExampleVideoCard = ({ video }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('generated'); // State to track selected video

    const handleVideoChange = (event) => {
        setSelectedVideo(event.target.value);
    };

    return (
        <div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:translate-y-[-10px] hover:shadow-lg">
                {/* Container for videos */}
                <div className="flex justify-center">
                    <video src={selectedVideo === 'generated' ? video.videourl : video.sourcevideourl} controls className="w-full h-auto rounded-lg" />
                </div>
                <div className="card-body">
                    <div className="flex justify-between items-center">
                        <button className="flex m-2 btn btn-secondary" onClick={() => setShowInfo(true)}>
                            {LsIcons.Text2Image_info_icon}
                        </button>
                    </div>
                    {video.sourcevideourl && (
                      <div className="flex justify-center mt-2">
                          <label className="mr-4">
                              <input
                                  type="radio"
                                  value="generated"
                                  checked={selectedVideo === 'generated'}
                                  onChange={handleVideoChange}
                                  className="mr-1"
                              />
                              Generated
                          </label>
                          <label>
                              <input
                                  type="radio"
                                  value="source"
                                  checked={selectedVideo === 'source'}
                                  onChange={handleVideoChange}
                                  className="mr-1"
                              />
                              Source
                          </label>
                      </div>
                    )}
                </div>
            </div>
            {showInfo && (
                <Dialog.Root open={showInfo} onOpenChange={setShowInfo}>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content className="DialogContent">
                        <Dialog.Title className="DialogTitle">Video Info</Dialog.Title>
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center justify-between">
                                <label className="font-bold">Prompt</label>
                                <button className="Button small" onClick={() => navigator.clipboard.writeText(video.prompt || '')}>
                                    {LsIcons.Text2Image_copy_svg}
                                </button>
                            </div>
                            <textarea
                                className="border p-2 rounded w-full"
                                rows={Math.min(Math.ceil((video.prompt || '').length / 45), 6)}
                                readOnly
                                value={video.prompt || ''}
                            />
                            <div>
                                <label className="font-bold">Negative Prompt</label>
                                <textarea
                                    className="border p-2 rounded w-full"
                                    rows={Math.min(Math.ceil((video.negativeprompt || '').length / 45), 6)}
                                    readOnly
                                    value={video.negativeprompt || ''}
                                />
                            </div>
                            <div>
                                <label className="font-bold">Other Parameters</label>
                                <textarea
                                    className="border p-2 rounded w-full"
                                    rows={3}
                                    readOnly
                                    value={JSON.stringify({
                                        seed: video.seed,
                                    }, null, 2) || ''}
                                />
                            </div>
                        </div>
                        <Dialog.Close asChild>
                            <button className="Button green mt-4">
                                Close
                            </button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Root>
            )}
        </div>
    );
};

export default ExampleVideoCard;
