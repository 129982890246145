import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ShimmerButton from './magicui/shimmer-button';
import ReactGA from 'react-ga4';

const AuthButtons = ({ setIsSideMenuOpen, setIsAuthenticated }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [formattedUsername, setFormattedUsername] = useState("");  // 新增一个状态来存储格式化后的用户名
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);  // 新增一个状态来存储是否为手机设备

  useEffect(() => {
    // 检测设备类型
    const checkDeviceType = () => {
      setIsMobile(window.innerWidth <= 768);  // 假设宽度小于等于768px的是手机设备
    };

    checkDeviceType();  // 初次检测
    window.addEventListener('resize', checkDeviceType);  // 当窗口大小变化时再检测

    return () => {
      window.removeEventListener('resize', checkDeviceType);  // 组件卸载时移除监听器
    };
  }, []);

  useEffect(() => {
    const storedUserInfo = Cookies.get('userInfo');
    if (storedUserInfo) {
      try {
        const parsedUserInfo = JSON.parse(storedUserInfo);
        setUserInfo(parsedUserInfo);
        setIsAuthenticated(true);
        // 当用户信息加载时，格式化用户名并存储
        if (parsedUserInfo.lusername || parsedUserInfo.name) {
          setFormattedUsername(formatUsername(parsedUserInfo.lusername || parsedUserInfo.name));
        }
      } catch (error) {
        console.error('Error parsing stored user info:', error);
      }
    }
  }, [setIsAuthenticated]);

  const logout = () => {
    console.log('Logged out');
    setIsAuthenticated(false);
    setUserInfo(null);
    localStorage.removeItem('luserid');
    localStorage.removeItem('accessToken');
    Cookies.remove('userInfo'); // 从 Cookie 中移除用户信息
  };

  const formatUsername = (username) => {
    return username.length > 12 ? `${username.substring(0, 12)}...` : username;
  };

  const handleLoginClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Login'
    });
    if (isMobile) {
      setIsSideMenuOpen(false);  // 仅在手机设备上关闭菜单
    }
    navigate('/login');
  };

  return (
    <div className="space-y-2">
      {userInfo ? (
        <>
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <img src={userInfo.icon || userInfo.picture} alt="User Avatar" className="w-full h-full object-cover" />
            </div>
            <div className="max-w-xs">
              <p className="text-lg font-bold" title={formattedUsername}>
                {formattedUsername}
              </p>
            </div>
          </div>
          <button onClick={logout} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 w-full mt-2">Logout</button>
        </>
      ) : (
        <ShimmerButton onClick={handleLoginClick} className="shadow-2xl">
        <span className="whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
        Login with Google
        </span>
      </ShimmerButton>
        // <button onClick={handleLoginClick} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-full">Login with Google</button>
      )}
    </div>
  );
};

export default AuthButtons;