import React from 'react';
import { BentoGrid, BentoGridItem } from './ui/bento-grid'
import FeatureCard from './FeatureCard';
import ReactGA from "react-ga4";

const FeatureGrid: React.FC = () => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "aitools" });
  const features = [
    {
      title: "Face retoucher",
      description: "Retouches faces to smooth skin and remove blemishes.",
      imageSrc: "/images/facerestore.jpg",
      linkTo: "/faceretoucher"
    },
    {
      title: "Background Remover",
      description: "Remove the background from your image with our AI-powered tool.",
      imageSrc: "/images/rembg.jpg",
      linkTo: "/rembg"
    },
    {
      title: "Face to sticker",
      description: "Create sticker from face.",
      imageSrc: "/images/sticker.jpg",
      linkTo: "/face2sticker"
    },
    {
        title: "AuraSr V2 Upscaler",
        description: "Upscale your image with our AuraSr AI tool.",
        imageSrc: "/images/AuraSrUpscaler.jpg",
        linkTo: "/aurasr"
      },
      {
        title: "Creative Upscaler",
        description: "Creative way to upscale your image to add details with prompt.",
        imageSrc: "/images/CreativeUpscaler.jpg",
        linkTo: "/creativeupscaler"
      },
    // 更多功能...
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">AI Tools</h1>
      <BentoGrid>
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            description={feature.description}
            imageSrc={feature.imageSrc}
            linkTo={feature.linkTo}
          />
        ))}
      </BentoGrid>
    </div>
  );
};

export default FeatureGrid;
