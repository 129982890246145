import React, { useState, useEffect } from 'react';
import TextareaAutosize from "react-textarea-autosize";
import { LsIcons } from './ui/LsIcons';
import ReactGA from "react-ga4";
import * as Slider from '@radix-ui/react-slider';
import "../css/slider.css";
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/tooltip.css"

const MessageInput = ({
    userInput, setUserInput, handleSendMessage, handleStopChat, handleClearChat,
    handleRegenerate, contextSize, setContextSize, isSettingsOpen, setIsSettingsOpen
}) => {
    const [scrollIndex, setScrollIndex] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);

    const handleSend = () => {
        setIsGenerating(true);
        handleSendMessage().finally(() => setIsGenerating(false));
    };

    const handleRegenerateWrapper = () => {
        setIsGenerating(true);
        handleRegenerate().finally(() => setIsGenerating(false));
    }

    const handleStopChatWrapper = () => {
        handleStopChat().finally(() => setIsGenerating(false));
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };
    const handleClickSettings = () => {
        setIsSettingsOpen(!isSettingsOpen)
        ReactGA.event({
            category: 'User',
            action: 'Clicked ChatStockSettings'
          });
    }
    
    const tip_messages = [
        "Click Run html for HTML code, prompt pseudo HTML to try your idea.",
        'Free multi ligural tts supported on chrome/edge desktop.',
        'Support render mermaid diagram, example: Draw a character relationship diagram for the Cinderella fairy tale using mermaid'
      ];

      useEffect(() => {
        const interval = setInterval(() => {
          setScrollIndex((prevIndex) => (prevIndex + 1) % tip_messages.length);
        }, 5000);
        return () => clearInterval(interval);
      }, [tip_messages.length]);

    return (
        <div className="absolute flex-grow bottom-2 flex flex-col items-center px-2 p-2 w-11/12 md:w-11/12 shadow-lg rounded-3xl p-4 bg-clip-padding bg-gray-200 bg-opacity-60 border backdrop-blur-md">
            <div className="flex flex-grow flex-wrap gap-2 justify-start w-full md:w-full mb-2">
            <Tooltip.Provider>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <button
                                onClick={handleClickSettings}
                                className="p-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                                ⚙
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                API Settings
                                <Tooltip.Arrow className="TooltipArrow" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>

                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <button onClick={handleClearChat} className="p-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500">
                                🗑️
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                Clean chat
                                <Tooltip.Arrow className="TooltipArrow" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>

                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <button
                                onClick={isGenerating ? handleStopChatWrapper : handleRegenerateWrapper}
                                className="p-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                                {isGenerating ? LsIcons.AIChatComponent_stopchat_svg_icon : LsIcons.AIChatComponent_regenerate_svg_icon}
                            </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                {isGenerating ? "Stop chat" : "Regenerate"}
                                <Tooltip.Arrow className="TooltipArrow" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
                <div className="slider-container">
                    {/*<input*/}
                    {/*    type="range"*/}
                    {/*    min="0"*/}
                    {/*    max="10"*/}
                    {/*    value={contextSize}*/}
                    {/*    onChange={(e) => setContextSize(parseInt(e.target.value))}*/}
                    {/*    className="slider-context"*/}
                    {/*    title="context size"*/}
                    {/*    style={{*/}
                    {/*        WebkitAppearance: 'none',*/}
                    {/*        height: '6px',*/}
                    {/*        background: '#f0f0f0',*/}
                    {/*        outline: 'none',*/}
                    {/*        opacity: '0.7',*/}
                    {/*        transition: 'opacity .2s',*/}
                    {/*        borderRadius: '5px',*/}
                    {/*        verticalAlign: 'middle',*/}
                    {/*        margin: 'auto 10px'*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Slider.Root
                        className="SliderRootSm"
                        value={[contextSize]}
                        max={10}
                        step={1}
                        onValueChange={(value) => setContextSize(value[0])}
                    >
                        <Slider.Track className="SliderTrackSm">
                            <Slider.Range className="SliderRange" />
                        </Slider.Track>
                        <Slider.Thumb className="SliderThumbSm" aria-label="Max tokens" />
                    </Slider.Root>
                    <div className="tooltip">Context Size: {contextSize}</div>

                </div>
                <span>{contextSize}</span>
                <div className="flex-1 overflow-hidden whitespace-nowrap">
            <div className="animate-marquee">
              {tip_messages[scrollIndex]}
            </div>
          </div>
            </div>
            <div className="flex flex-wrap gap-2 justify-left items-center relative w-full">
                <TextareaAutosize
                    autoComplete="off"
                    value={userInput}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setUserInput(e.target.value)}
                    name="message"
                    minRows={2}
                    placeholder="Input prompt(shift+return for newline)..."
                    className="flex-grow bg-opacity-60 rounded-lg bg-accent text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 flex items-center h-12 resize-none overflow-hidden dark:bg-card"
                />
                <div className="absolute right-0 top-2 text-blue-500 hover:text-blue-700" onClick={handleSend}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default MessageInput;
