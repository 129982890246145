import React, { useState, useEffect } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';

const cities = [
    { name: 'Your Time', shortName: 'Yours', timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    { name: 'Los Angeles', shortName: 'LA', timezone: 'America/Los_Angeles' },
    { name: 'New York', shortName: 'NYC', timezone: 'America/New_York' },
    { name: 'London', shortName: 'LDN', timezone: 'Europe/London' },
    { name: 'Paris', shortName: 'PAR', timezone: 'Europe/Paris' },
    { name: 'Beijing', shortName: 'BJ', timezone: 'Asia/Shanghai' },
    { name: 'Tokyo', shortName: 'TYO', timezone: 'Asia/Tokyo' },
];

const CityTime = ({ city, shortName, timezone }) => {
    const [time, setTime] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            if (shortName === 'Yours') {
                setTime(now.toLocaleTimeString('en-US', { timeZone: timezone, hour12: false }));
            } else {
                setTime(now.toLocaleTimeString('en-US', {
                    timeZone: timezone,
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit'
                }));
            }
        };

        updateTime();
        const timer = setInterval(updateTime, 1000);
        return () => clearInterval(timer);
    }, [shortName, timezone]);

    const textColor = shortName === 'Yours' ? 'text-black' : 'text-black';

    return (
        <Tooltip.Provider>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <div className="flex min-w-[80px] flex-col justify-items-start p-2 sm:p-2 m-1">
                        <div className={`${textColor} font-semibold text-[black(0.7rem,1vw,1rem)] whitespace-nowrap cursor-help`}>
                            {shortName}
                        </div>
                        <div className={`${textColor} text-[black(0.8rem,1.5vw,1.2rem)] min-w-[80px] whitespace-nowrap`}>
                            {time}
                        </div>
                    </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className="bg-gray-800 text-white px-2 py-1 rounded text-sm" sideOffset={5}>
                        {city}
                        <Tooltip.Arrow className="fill-gray-800" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};

const WorldClock = () => {
    return (
        <div className="flex w-11/12">
            <div className="w-full border-l-2 border-r-2 border-gray-300 rounded-l-3xl rounded-r-3xl p-2 sm:p-2 md:p-6">
                    <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-7 gap-2 sm:gap-2 md:gap-4">
                    {cities.map((city) => (
                        <CityTime key={city.name} city={city.name} shortName={city.shortName} timezone={city.timezone} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WorldClock;
