import React, { useState, useEffect } from 'react';
import APISettings from './APISettings';
import { AnimatedSubscribeButton } from './magicui/animated-subscribe-button';
import { LsIcons } from './ui/LsIcons';
import "../css/radixswitch.css"
import * as Switch from '@radix-ui/react-switch';

const SettingsSidebar = ({
    saveSettings, baseurl, setBaseurl, model, setModel, apiKey, setApiKey,
    maxTokens, setMaxTokens, temperature, setTemperature, onCloud, setOnCloud, isOllama,setIsSettingsOpen
}) => {
    const [showMessage, setShowMessage] = useState(false);

    const handleSaveSettings = () => {
        saveSettings();
        setShowMessage(true);
    };

    useEffect(() => {
        if (showMessage) {
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showMessage]);

    return (
        <div className="flex relative flex-col justify-center items-center">
            <APISettings
                asSidebar={true}
                baseurl={baseurl}
                setBaseurl={setBaseurl}
                model={model}
                setModel={setModel}
                apiKey={apiKey}
                setApiKey={setApiKey}
                maxTokens={maxTokens}
                setMaxTokens={setMaxTokens}
                temperature={temperature}
                setTemperature={setTemperature}
                isOllama={isOllama}
                setIsSettingsOpen={setIsSettingsOpen}
            >
                {isOllama !== "1" && (
                    <label className="flex justify-left items-center m-4">
                        <span className="flex m-4">On Cloud</span>
                        <Switch.Root checked={onCloud} onCheckedChange={setOnCloud} className="SwitchRoot">
                            <Switch.Thumb className="SwitchThumb"/>
                        </Switch.Root>
                    </label>
                )}
                <AnimatedSubscribeButton
                    buttonColor="#000000"
                    buttonTextColor="#ffffff"
                    subscribeStatus={false}
                    initialText={
                        <span className="group inline-flex items-center">
                            Save settings{" "}
                            {LsIcons.Settings_change_name_svg_icon}
                        </span>
                    }
                    changeText={
                        <span className="group inline-flex items-center">
                            {LsIcons.Settings_change_name_svg_icon}
                             now{" "}
                        </span>
                    }
                    onClick={handleSaveSettings}
                />
  {/*              <button*/}
  {/*                  onClick={handleSaveSettings}*/}
  {/*                  className="px-8 py-2 justify-left items-center rounded-full relative bg-slate-700 text-white text-sm hover:shadow-2xl hover:shadow-white/[0.1] transition duration-200 border border-slate-600">*/}
  {/*                  <div*/}
  {/*                      className="absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl  bg-gradient-to-r from-transparent via-teal-500 to-transparent"/>*/}
  {/*                  <span className="relative z-20">*/}
  {/*  Save settings*/}
  {/*</span>*/}
  {/*              </button>*/}
                {showMessage && (
                    <div className="mt-2 text-green-600">
                        Settings saved successfully!
                    </div>
                )}
            </APISettings>
        </div>
    );
};

export default SettingsSidebar;
