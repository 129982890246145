import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryFilter from './CategoryFilter';
import PostsList from './PostsList';
import '../css/blog.css'; // Import the CSS file for Blog styles

const Blog = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId === 'all' ? null : categoryId);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="category-filter-card bg-white shadow-lg rounded-lg p-4 mb-6 transition-transform transform hover:translate-y-[-10px] hover:shadow-xl">
        <CategoryFilter
          categories={categories}
          selectedCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
        />
      </div>
      <PostsList selectedCategory={selectedCategory} />
    </div>
  );
};

export default Blog;
