import React, { useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

const FalApiKeyDialog = ({ apiKey, setApiKey, isOpen, setIsOpen }) => {
  useEffect(() => {
    const savedApiKey = localStorage.getItem('falApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, [setApiKey]);

  const handleSave = (e) => {
    e.preventDefault();
    localStorage.setItem('falApiKey', apiKey);
    setIsOpen(false);
  };

  const handleGetApiKey = () => {
    window.open('https://fal.ai/dashboard/keys', '_blank', 'noopener,noreferrer');
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 rounded-xl p-6 w-full max-w-md shadow-2xl">
          <Dialog.Title className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-white">Set API Key</Dialog.Title>
          <form onSubmit={handleSave} className="space-y-4">
            <div className="relative">
              <input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Enter your FAL API key"
                className="w-11/12 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
              />
            </div>
            <div className="flex justify-between items-center space-x-4">
              <button
                type="button"
                onClick={handleGetApiKey}
                className="flex-1 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Get API Key
              </button>
              <button 
                type="submit" 
                className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Save
              </button>
            </div>
          </form>
          <Dialog.Close className="absolute top-2 right-2 p-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default FalApiKeyDialog;
