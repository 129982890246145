import { useRef } from 'react';
import { ChatOpenAI } from "@langchain/openai";
import { ChatGroq } from "@langchain/groq";
import { HumanMessage, AIMessage } from "@langchain/core/messages";
import APIChatDB from '../db/APIChatDb';
import { toast } from 'react-toastify';
import ReactGA from "react-ga4";

export const useChat = ({ sessionId, baseurl, model, apiKey, maxTokens, temperature, messages, setMessages, setUserInput, contextSize, userInput, withCloud }) => {
    const controller = useRef(new AbortController());

    const addMessage = async (message) => {
        await APIChatDB.messages.add(message);
    };

    const removeLastMessage = async () => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage && lastMessage.role === 'assistant') {
            await APIChatDB.messages
                .where({ sessionId, role: 'assistant', content: lastMessage.content })
                .delete();
            setMessages(prevMessages => prevMessages.slice(0, -1));
        }
    };

    const handleSendMessage = async (userInput) => {
        if (!userInput.trim()) return;

        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatSendMessage'
        });

        const userMessage = { sessionId, role: 'user', content: userInput };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        await addMessage(userMessage);
        setUserInput('');

        const messagePairs = Math.floor(contextSize / 2);
        const historicalMessages = messages.slice(-(messagePairs * 2));

        const messageArray = historicalMessages.map(msg =>
            msg.role === 'user' ? new HumanMessage(msg.content) : new AIMessage(msg.content)
        );
        messageArray.push(new HumanMessage(userInput));

        let baseURL = baseurl;
        if (withCloud) {
            // Construct the full URL for the proxy
            baseURL = `${window.location.origin}/api/chatproxy?base_url=${encodeURIComponent(baseurl)}`;
        }

        let chat = new ChatOpenAI({
            configuration: {
                baseURL: baseURL,
                // maxTokens: parseInt(maxTokens, 10),
                // apiKey: apiKey,
                // temperature: parseFloat(temperature),
                // model: model,
                // streaming: true,
            },
            apiKey: apiKey,
            temperature: parseFloat(temperature),
            // baseURL: baseURL,
            maxTokens: parseInt(maxTokens, 10),
            model: model,
            modelName: model,
            streaming: true,
        });

        // if (isGroqApiDomain(baseurl)) {
        //     chat = new ChatGroq({
        //         apiKey: apiKey,
        //         baseURL: baseURL,
        //         maxTokens: parseInt(maxTokens, 10),
        //         temperature: parseFloat(temperature),
        //         model: model,
        //         streaming: true,
        //     });
        // }

        controller.current = new AbortController();
        const signal = controller.current.signal;

        try {
            const stream = await chat.stream(messageArray, { signal });

            let aiResponseContent = '';
            for await (const chunk of stream) {
                aiResponseContent += chunk.content;
                setMessages(prevMessages => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (lastMessage && lastMessage.role === 'assistant' && !lastMessage.final) {
                        return [
                            ...prevMessages.slice(0, -1),
                            { ...lastMessage, content: aiResponseContent }
                        ];
                    } else {
                        return [
                            ...prevMessages,
                            { sessionId, role: 'assistant', content: aiResponseContent, final: false }
                        ];
                    }
                });
            }

            setMessages(prevMessages => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage && lastMessage.role === 'assistant') {
                    return [
                        ...prevMessages.slice(0, -1),
                        { ...lastMessage, final: true }
                    ];
                } else {
                    return prevMessages;
                }
            });

            const aiMessage = { sessionId, role: 'assistant', content: aiResponseContent, final: true };
            await addMessage(aiMessage);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Chat aborted');
            } else {
                console.error('Error during chat:', error);
            }
        }
    };

    const handleRegenerate = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatRegenerateMessage'
        });
        // Remove the last AI message from state and DB
        await removeLastMessage();

        // Find the last user message
        const lastUserMessage = messages.slice().reverse().find(msg => msg.role === 'user');
        if (lastUserMessage) {
            // Send the last user message as a new prompt
            await handleSendMessage(lastUserMessage.content);
        }
    };

    const handleStopChat = () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatStopChat'
        });
        if (controller.current) {
            controller.current.abort();
        }
    };

    const handleClearChat = async () => {
        await APIChatDB.messages.where('sessionId').equals(sessionId).delete();
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatClearChat'
        });
        setMessages([]);
    };

    const handleCopyMessage = (content) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatCopyMessage'
        });
        navigator.clipboard.writeText(content).then(() => {
            toast.success('Message copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const handleCopyCode = (code) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatCopyCode'
        });
        navigator.clipboard.writeText(code).then(() => {
            toast.success('Code copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    return {
        handleSendMessage,
        handleRegenerate,
        handleStopChat,
        handleClearChat,
        handleCopyMessage,
        handleCopyCode
    };
};

function isGroqApiDomain(baseUrl) {
    try {
        const url = new URL(baseUrl);
        return url.hostname === 'api.groq.com';
    } catch (e) {
        return false;
    }
}
