import React, { useState, useRef, useEffect } from 'react';
import * as fal from "@fal-ai/serverless-client";
import ReactCompareImage from 'react-compare-image';
import FalApiKeyDialog from './FalApiKeyDialog';
import FalImageUploader from './FalImageUploader';
import { LsIcons } from './ui/LsIcons';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

const AuraSrUpscaler = () => {
  const [originalImage, setOriginalImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
  const [upscalingFactor, setUpscalingFactor] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState({ left: false, right: false });
  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "aurasr" });
    const savedApiKey = localStorage.getItem('falApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, []);

  const handleImageUpload = async (file) => {
    setOriginalImage(URL.createObjectURL(file));
    setProcessedImage(null);
    uploadedFileRef.current = file;
    setImagesLoaded({ left: false, right: false });
  };

  const processImage = async () => {
    ReactGA.event({
        category: 'User',
        action: 'Clicked AuraSR Upscale'
      });
    if (!originalImage || !apiKey || !uploadedFileRef.current) {
      setIsApiKeyDialogOpen(true);
      return;
    }

    setIsProcessing(true);
    setIsLoading(true);
    setImagesLoaded({ left: false, right: false });

    try {
      fal.config({ credentials: apiKey });

      const uploadedUrl = await fal.storage.upload(uploadedFileRef.current);

      const result = await fal.subscribe("fal-ai/aura-sr", {
        input: {
          image_url: uploadedUrl,
          upscaling_factor: upscalingFactor,
          overlapping_tiles: true,
          checkpoint: "v2"
        },
        logs: true,
        onQueueUpdate: (update) => {
          if (update.status === "IN_PROGRESS") {
            update.logs.map((log) => log.message).forEach(console.log);
          }
        },
      });

      setProcessedImage(result.image.url);
      console.log("Processed image URL:", result.image.url);
    } catch (error) {
      console.error("Error processing image:", error);
      setIsLoading(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleImageLoad = (side) => {
    setImagesLoaded((prev) => ({ ...prev, [side]: true }));
  };

  useEffect(() => {
    if (imagesLoaded.left && imagesLoaded.right) {
      setIsLoading(false);
    }
  }, [imagesLoaded]);

  const downloadProcessedImage = () => {
    if (processedImage) {
        window.open(processedImage, '_blank');
      }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
                <Helmet>
        <title>Image upscale Tool - Upscale image with best quality easily</title>
        <meta name="description" content="Use our advanced AI-powered image upscale tool to easily upscale your images. Perfect for e-commerce, graphic design, and more." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebApplication",
            "name": "Image upscale Tool",
            "description": "AI-powered tool to upscale your images",
            "url": "https://llmstock.com/aurasr",
            "applicationCategory": "ImageProcessingApplication",
            "operatingSystem": "Web"
          })}
        </script>
      </Helmet>
      <h1 className="text-4xl font-bold text-center mb-8">Aura-SR Upscaler</h1>
      
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        <FalImageUploader onImageUpload={handleImageUpload} fileInputRef={fileInputRef} />
        
        <div className="mt-6">
          {originalImage && processedImage === null && (
            <img src={originalImage} alt="Original Image" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
          )}
          {isLoading && (
            <div className="flex justify-center items-center h-16">
              <div className="loader"></div> {/* You can use any loading indicator here */}
            </div>
          )}
          {!isLoading && processedImage && (
            <ReactCompareImage
              leftImage={originalImage}
              rightImage={processedImage}
            />
          )}
          {processedImage && (
            <>
              <img
                src={originalImage}
                alt="Original"
                style={{ display: 'none' }}
                onLoad={() => handleImageLoad('left')}
              />
              <img
                src={processedImage}
                alt="Processed"
                style={{ display: 'none' }}
                onLoad={() => handleImageLoad('right')}
              />
            </>
          )}
          <select
            value={upscalingFactor}
            onChange={(e) => setUpscalingFactor(Number(e.target.value))}
            className="mt-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value={4}>4x</option>
          </select>
          <div className="mt-4 flex justify-center space-x-4">
            <button
              onClick={() => setIsApiKeyDialogOpen(true)}
              className="px-2 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              {LsIcons.Gear_svg_icon}
            </button>
            <button
              onClick={processImage}
              disabled={!originalImage || isProcessing}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
            >
              {isProcessing ? 'Processing...' : 'Upscale Image'}
            </button>
            {processedImage && (
              <button
                onClick={downloadProcessedImage}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Download Processed Image
              </button>
            )}
          </div>
        </div>
      </div>
      
      <FalApiKeyDialog 
        apiKey={apiKey}
        setApiKey={setApiKey}
        isOpen={isApiKeyDialogOpen}
        setIsOpen={setIsApiKeyDialogOpen}
      />
    </div>
  );
};

export default AuraSrUpscaler;
